import React from 'react'
import { Link } from 'wouter'

import { Avatar, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'

import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'

const LinkOrText = ({ text, isLink, url, fadedText }: { text: string; isLink: boolean; url: string | undefined; fadedText: boolean }): JSX.Element =>
  isLink && url ? (
    <Link to={url} onClick={(e) => e.stopPropagation()}>
      <ChakraLink>
        <TruncatedTextWithTooltip text={text} color={fadedText ? 'faded' : 'inherit'} />
      </ChakraLink>
    </Link>
  ) : (
    <TruncatedTextWithTooltip text={text} color={fadedText ? 'faded' : 'inherit'} />
  )

type Props = {
  owner?: string
  ownerTitle?: string
  projectSlug?: string
  projectName?: string
  targetSlug?: string
  targetName?: string
  runNumber?: number | string
  isAccessible?: boolean
  overlayTextOnMobile?: boolean
  disableLinks?: boolean
  hide?: RunRowParentLinkItemType[]
  inline?: boolean
  fadedText?: boolean
}

const RunRowParentLinks: React.FC<Props> = (props) => {
  const {
    owner,
    ownerTitle,
    projectSlug,
    projectName,
    targetSlug,
    targetName,
    runNumber,
    isAccessible = false,
    disableLinks = false,
    hide = [],
    fadedText = true
  } = props

  return (
    <Stack direction="row" gap={2} divider={<Text>/</Text>} align="center" wrap="wrap">
      {owner && !hide.includes('owner') && (
        <React.Fragment>
          <Avatar name={owner} bg="gray.400" color="white" size="xs" />
          <LinkOrText isLink={!disableLinks} url={`/${owner}`} text={ownerTitle || owner} fadedText={fadedText} />
        </React.Fragment>
      )}

      {projectSlug && !hide.includes('projectSlug') && (
        <React.Fragment>
          <Avatar name={projectSlug} bg="gray.400" color="white" size="xs" />
          <LinkOrText
            isLink={!disableLinks && isAccessible}
            url={owner && `/${owner}/${projectSlug}`}
            text={projectName || projectSlug}
            fadedText={fadedText}
          />
        </React.Fragment>
      )}

      {targetSlug && !hide.includes('targetSlug') && (
        <LinkOrText
          isLink={!disableLinks && isAccessible}
          url={owner && projectSlug && `/${owner}/${projectSlug}/${targetSlug}`}
          text={targetName || targetSlug}
          fadedText={fadedText}
        />
      )}

      {runNumber && !hide.includes('runNumber') && (
        <LinkOrText
          isLink={!disableLinks && isAccessible}
          url={owner && projectSlug && targetSlug && `/${owner}/${projectSlug}/${targetSlug}/${runNumber}`}
          text={`Run ${runNumber}`}
          fadedText={fadedText}
        />
      )}
    </Stack>
  )
}

export type RunRowParentLinkItemType = 'owner' | 'projectSlug' | 'targetSlug' | 'runNumber'

export default RunRowParentLinks
