import { Box, Card, CardBody, CardBodyProps, CardHeader, Heading } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: React.ReactNode
  actions?: React.ReactNode
  title?: string
  headerActions?: React.ReactNode
  CardBodyProps?: CardBodyProps
}

export function TableCard({ actions, children, title, headerActions, CardBodyProps }: Props) {
  return (
    <Card>
      {title && (
        <CardHeader>
          <Heading variant="cardHeading" textTransform="capitalize" marginTop={2}>
            {title}
          </Heading>
          {headerActions && <Box>{headerActions}</Box>}
        </CardHeader>
      )}
      {actions && (
        <CardHeader paddingX={8} gap={3} paddingY={6}>
          {actions}
        </CardHeader>
      )}
      <CardBody paddingTop={4} {...CardBodyProps}>
        {children}
      </CardBody>
    </Card>
  )
}
