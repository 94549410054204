import { Box, FormControl, FormHelperText, FormLabel, SimpleGrid, Stack, Image } from '@chakra-ui/react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

type Props = { svgLink: string; link: string }

const ProjectBadgeLink = ({ svgLink, link }: Props) => {
  return (
    <SimpleGrid spacing={2} columns={2}>
      <FormControl>
        <FormLabel>Project Defect Badge</FormLabel>
        <FormHelperText maxW={96}>
          Add a Mayhem badge to your repository to show the count of defects in your project. Copy/paste the following line to the README.md file of
          your repository to display the badge!
        </FormHelperText>
      </FormControl>

      <Stack>
        <CodeBlock>{link}</CodeBlock>
        <Box>
          <Image src={svgLink} float="left" />
        </Box>
      </Stack>
    </SimpleGrid>
  )
}

export default ProjectBadgeLink
