import { Badge, Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
  nDefectsActiveHigh?: number
  nDefectsActiveMedium?: number
  nDefectsActiveLow?: number
  nDefects?: number
  children: React.ReactNode
}

export function DefectsNoSeverity({ nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow, nDefects, children }: Props) {
  if ([nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow].every((defect) => defect === 0) || nDefects === 0) {
    return (
      <Badge variant="subtle" colorScheme="success" borderRadius="lg">
        <Text textColor="normalGreen" fontWeight="bold">
          No Defects
        </Text>
      </Badge>
    )
  }

  return children
}
