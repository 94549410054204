import { Center, Heading, Spinner, VStack } from '@chakra-ui/react'

export function MayhemPageLoader() {
  return (
    <Center height="100vh" color="faded">
      <VStack>
        <Spinner size="xl" />
        <Heading>Loading Mayhem...</Heading>
        <Heading size="md">Hang on for a second</Heading>
      </VStack>
    </Center>
  )
}
