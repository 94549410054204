import { IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import DownloadIcon from '@material-design-icons/svg/sharp/download.svg?react'

import { stringifyUrl } from '@/util/location'
import { getRunNumberUrl } from '@/features/runs/utils'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  optimizedOnly?: boolean
  inputOnly?: boolean
}

export function TestsuiteButton({ owner, projectSlug, targetSlug, runNumber, optimizedOnly = false, inputOnly = false }: Props): JSX.Element {
  const filterParams = { run_number: runNumber, optimized_only: optimizedOnly, input_only: inputOnly }

  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="download tests" icon={<DownloadIcon />} variant="outline" />
      <Portal>
        <MenuList>
          <MenuItem
            data-selenium-id="testsuite"
            as="a"
            download="testsuite.tar"
            href={stringifyUrl(`/api/v2/owner/${owner}/project/${projectSlug}/target/${targetSlug}/testsuite.tar`, filterParams)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Test Case Files (.tar)
          </MenuItem>
          <MenuItem
            data-selenium-id="testsuite"
            as="a"
            download="tests.csv"
            href={stringifyUrl(`${getRunNumberUrl(owner, projectSlug, targetSlug, runNumber)}/testcase-results.csv`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Test Case Results (.csv)
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
