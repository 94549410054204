// these can be used for semantically naming colors if we want
export const semanticTokens = {
  colors: {
    // matches --chakra-colors-chakra-body-bg
    body: {
      default: '#FFFFFF',
      _dark: 'gray.900'
    },

    sidebarBg: {
      default: 'gray.50',
      _dark: 'gray.950'
    },
    pageBg: {
      default: 'gray.50',
      _dark: 'gray.950'
    },

    // Override some chakra variables
    // to have global reach over basic variables
    // see https://github.com/chakra-ui/chakra-ui/blob/05f922f2fada732f04d93e13bd53434805f4d619/packages/theme/src/semantic-tokens.ts
    // used to have some overrides here, leaving the above link in case any are needed again in the future
    // (although in testing today this doesn't actually seem to work)

    // matches --chakra-colors-chakra-body-text
    primaryText: { default: 'gray.800', _dark: 'whiteAlpha.900' },
    // tweaked default Chakra body font colors
    faded: { default: 'blackAlpha.600', _dark: 'whiteAlpha.700' },

    // semantic colors
    primary: { default: 'brand.500', _dark: 'brand.200' },
    border: { default: 'gray.200', _dark: 'gray.700' },
    bad: { default: 'bad.500', _dark: 'bad.400' },
    good: { default: 'success.500', _dark: 'success.400' },
    warning: { default: 'yellow.500', _dark: 'yellow.400' },
    error: { default: 'orange.500', _dark: 'orange.400' },
    severityHigh: { default: 'severityHigh.500', _dark: 'severityHigh.200' },
    severityMedium: { default: 'severityMedium.500', _dark: 'severityMedium.200' },
    severityLow: { default: 'severityLow.500', _dark: 'severityLow.200' },
    '-severityLow': { default: 'severityLow.200', _dark: 'severityLow.500' },
    graphColor1: { default: '#EC000E', _dark: '#D60011' },
    graphColor2: { default: '#FFBB44', _dark: '#E7A941' },
    graphColor3: { default: '#DCFF10', _dark: '#C7E612' },
    graphColor4: { default: '#F26A8D', _dark: '#DB6083' },
    graphColor5: { default: '#2BB7E3', _dark: '#28A5D0' },
    graphColor6: { default: '#1B998B', _dark: '#1A8A81' },
    subtleBg: { default: 'gray.100', _dark: 'gray.800' },

    chartSeries1: { default: 'brand.600', _dark: 'brand.500' },
    chartSeries2: { default: 'brand.400', _dark: 'brand.300' },
    chartAxis: { default: 'gray.600', _dark: 'gray.400' },

    semiBoldGreen: { default: 'green.600', _dark: 'green.400' },
    normalGreen: { default: 'green.400', _dark: 'green.200' },

    code: { default: 'brand.700', _dark: 'brand.500' },

    'subnav-section-header': { default: 'gray.500', _dark: 'gray.700' },

    accent: {
      default: 'brand.500',
      _dark: 'brand.200'
    },
    'bg-accent': 'body-dark',
    'bg-accent-subtle': 'brand.800',
    'on-accent': 'white',
    'on-accent-muted': 'brand.50',
    'on-accent-subtle': 'brand.100',
    mutedAccent: {
      default: 'brand.600',
      _dark: 'brand.100'
    },

    hoverBg: {
      default: 'brand.50',
      _dark: 'gray.800'
    },
    hoverColor: {
      default: 'gray.900',
      _dark: 'white'
    },
    // active = when button/etc is being clicked
    activeBg: {
      default: 'brand.200',
      _dark: 'gray.700'
    },
    activeColor: {
      default: 'gray.900',
      _dark: 'white'
    },
    // selected = when indicating the currently selected page/preview
    selectedBg: {
      default: 'brand.100',
      _dark: 'gray.900'
    },
    labelBg: {
      default: 'brand.50',
      _dark: 'gray.700'
    },
    selectedColor: {
      default: 'gray.800',
      _dark: 'white'
    },
    purpleBg: {
      default: 'purple.50',
      _dark: 'gray.500'
    },
    purpleColor: {
      default: 'purple.500',
      _dark: 'gray.800'
    }
  }
}
