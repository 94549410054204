import { Box, Flex, Heading, Spacer } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

const RunStatusHoverSection = ({
  icon = '',
  data = []
}: {
  icon?: React.ReactNode
  data: { header: string; description: ReactNode }[]
}): JSX.Element => (
  <Flex p={4}>
    <Box alignSelf="center" justifySelf="center" width={8} height={8} display="flex" alignItems="center" justifyContent="center">
      {icon}
    </Box>
    <Spacer />
    <Box textAlign="right" display="flex" flexDirection="column" gap={2}>
      {data.map((datum, i) => {
        const { header = '', description = '' } = datum
        return (
          <Box key={`${header}_${i}`}>
            <Heading size="md" justifyContent="end">
              {header}
            </Heading>
            {description}
          </Box>
        )
      })}
    </Box>
  </Flex>
)

export default RunStatusHoverSection
