import { skipToken } from '@reduxjs/toolkit/query'
import { useRoute } from 'wouter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { useMemo } from 'react'

import { WorkspaceDefaultRedirect } from './WorkspaceDefaultRedirect'

import { useGetAccountQuery } from '@/redux/api/workspace'

import { WorkspaceSidebar } from '@/features/workspace-sidebar/WorkspaceSidebar'

import { Shell } from '@/components/Shell/Shell'

import { clearMruWorkspace } from '@/features/workspace-dashboard/mru.slice'
import { usePermissions } from '@/hooks'

interface Props {
  children: JSX.Element
}

export const WorkspaceLayout = ({ children }: Props) => {
  const dispatch = useDispatch()

  const [_, workspaceParams] = useRoute('/:workspace/*?')
  const [__, projectParams] = useRoute('/:workspace/:project/*?')
  const matchDsbomCompare = useRoute('/:workspace/-/dynamic-sbom-compare')[0]

  const matchUserSettings = useRoute('/-/settings/user/*?')[0]
  const matchWorkspaceSettings = useRoute('/:workspace/-/settings/*?')[0]
  const matchIntegrations = useRoute('/:workspace/-/integrations')[0]
  const matchAdminSettings = useRoute('/-/settings/admin/*?')[0]
  const matchPlan = useRoute('/:workspace/-/plan')[0]
  const matchSettings = matchUserSettings || matchAdminSettings || matchWorkspaceSettings || matchIntegrations || matchPlan

  if (!workspaceParams) {
    // this should only happen if the workspace layout is rendered without matching any workspace
    throw new Error('Workspace parameter was not matched')
  }
  const currentUserSlug = useSelector((state: RootState) => state.auth.user.userSlug)
  const defaultWorkspace = useSelector((state: RootState) => state.workspace.mru) || currentUserSlug
  const workspace = workspaceParams?.workspace === '-' ? defaultWorkspace : workspaceParams?.workspace
  const project = workspaceParams?.workspace === '-' ? '-' : projectParams?.project

  const submenu = matchSettings ? 'settings' : project && project !== '-' ? 'project' : undefined

  const workspaceSidebar = useMemo(
    () => <WorkspaceSidebar workspace={workspace} project={project} submenu={submenu} />,
    [workspace, project, submenu]
  )

  const { isError } = useGetAccountQuery(workspace ? { owner: workspace } : skipToken)

  const { isError: orgMemberError, userSlug, isAdmin } = usePermissions(workspace)

  // Only admins or workspace members can view the workspace page. If viewing a project,
  // this check is skipped, since projects may be shared beyond a workspace with external
  // collaborators.
  const isWorkspaceViewBlocked = !project && !isAdmin && userSlug !== workspace && !!orgMemberError

  if (isError || isWorkspaceViewBlocked) {
    dispatch(clearMruWorkspace())
    return <WorkspaceDefaultRedirect />
  }

  return (
    <Shell sidebar={workspaceSidebar} submenuOpen={!!submenu} showPageAccentBackground={matchDsbomCompare}>
      {children}
    </Shell>
  )
}
