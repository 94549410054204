import { useEffect } from 'react'

import { Page } from '../../components/Page'
import { CreateAPIProjectContainer } from '../run-api-create/CreateAPIProject'
import { getWorkspacePageTitle } from '../../titles'
import { useAppSelector } from '../../hooks'

import { MapiSpecUrlExample, MapiUrlExample, selectNewApiRun } from './create-api-run.slice'

export const CreateApiRunPage = ({ workspace }: CreateApiRunPageProps) => {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'New API project')
  }, [workspace])

  const { apiUrl = MapiUrlExample, specUrl = MapiSpecUrlExample } = useAppSelector(selectNewApiRun)
  return (
    <Page header="Add New API Project">
      <CreateAPIProjectContainer workspace={workspace} url={apiUrl} openApiSpec={specUrl} />
    </Page>
  )
}

export interface CreateApiRunPageProps {
  workspace: string
}
