import { Tooltip } from '@chakra-ui/react'

interface Props {
  isHasRun: boolean
  children: React.ReactNode
}

export default function TargetNoRun({ isHasRun, children }: Props) {
  if (!isHasRun) {
    return (
      <Tooltip label="No runs found for this target" bg="pageBg" color="invert" borderRadius={8} maxWidth={600}>
        -
      </Tooltip>
    )
  }

  return children
}
