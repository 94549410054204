import { useEffect } from 'react'
import { Breadcrumb, BreadcrumbItem, Heading, SimpleGrid } from '@chakra-ui/react'

import { getProjectPageTitle } from '../../titles'
import { CardDefectTrend } from '../../components/CardDefectTrend'

import { ProjectOverviewStats } from './ProjectOverviewStats'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'
import { ProjectTargetsList } from '@/features/target/TargetsForProjectList'

type Props = {
  workspace: string
  project: string
}

export function ProjectOverviewPage(props: Props) {
  const { workspace: owner, project: projectSlug } = props

  useEffect(() => {
    document.title = getProjectPageTitle(owner, projectSlug)
  }, [owner, projectSlug])

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem isCurrentPage>
            <TruncatedTextWithTooltip text={projectSlug} />
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>{projectSlug}</Heading>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap={6} mb={8}>
        <CardDefectTrend workspace={owner} project={projectSlug} />
        <ProjectOverviewStats owner={owner} projectSlug={projectSlug} />
      </SimpleGrid>

      <ProjectTargetsList owner={owner} projectSlug={projectSlug} />
    </Page>
  )
}
