import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink, useLocation } from 'wouter'

import { Feature } from 'flagged'

import { ErrorPanel } from '../../components/ErrorPanel'
import { FEATURE_LIFECYCLE_MANAGEMENT } from '../../featureFlags'
import { useQuery } from '../../hooks'
import { useGetDefectByTargetQuery, useGetTestcaseReportByRunAndChecksumQuery, useGetTestcaseReportsByDefectQuery } from '../../redux/api/defects'
import { setQueryParams } from '../../util/location'

import { getDefectIcon } from '../defect-report/utils'
import { JiraIssue } from '../defect-jira/JiraIssue'

import { DefectSeverityBadge } from './DefectSeverityBadge'
import { DefectStatusBadge } from './DefectStatusBadge'
import { DefectSeverityExplainer } from './DefectSeverityExplainer'
import { CodeDefectExampleTestcase } from './CodeDefectExampleTestcase'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber?: number
  isModal?: boolean
}

export function CodeDefectPreview({ owner, projectSlug, targetSlug, defectNumber, runNumber, isModal = false }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const exampleTestcaseSha = queryParams.get('exampleTestcase')

  const {
    isFetching: isDefectFetching,
    data: defect,
    isError: defectIsError
  } = useGetDefectByTargetQuery({ owner, projectSlug, targetSlug, defectNumber })

  const {
    from_valgrind: fromValgrind,
    severity_level: severityLevel,
    severity,
    title,
    cwe_description,
    cwe_link,
    cwe_number,
    cwe_type,
    state
  } = defect || {}

  const { data: exampleTestcaseReports, isFetching: isFetchingTestcaseReports } = useGetTestcaseReportsByDefectQuery(
    { owner, projectSlug, targetSlug, defectNumber, perPage: 5 },
    { skip: exampleTestcaseSha !== null }
  )
  const { testcase_reports: testcaseReports = [] } = exampleTestcaseReports || {}

  // use the first test case as the main example to show (if one is not specified)
  const { sha256: exampleTestcaseShaFromList = null, run_number: exampleTestcaseRunNumberFromList } = testcaseReports.at(0) || {}

  // fetch the example test case report (either specified from the defects list or picked from the
  // list of related test cases above) to get the appropriate crash or valgrind error backtrace
  const { data: exampleTestcaseReport, isFetching: isFetchingExampleTestcaseReport } = useGetTestcaseReportByRunAndChecksumQuery(
    {
      owner,
      projectSlug,
      targetSlug,
      runNumber: runNumber || exampleTestcaseRunNumberFromList || 0,
      sha256: exampleTestcaseSha || exampleTestcaseShaFromList || ''
    },
    { skip: exampleTestcaseSha === null && exampleTestcaseShaFromList === null }
  )

  if (defectIsError) {
    return <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />
  }

  const defectPageUrl = `/${owner}/${projectSlug}/${targetSlug}/-/defects/${defectNumber}/runs/${runNumber}`
  const closeDefectPreview = (): void => {
    const newUrl = setQueryParams({
      location,
      queryParams: queryParams,
      params: [
        { param: 'defect', value: null },
        { param: 'exampleTestcase', value: null },
        { param: 'defectTarget', value: null },
        { param: 'defectLastRunNumber', value: null }
      ]
    })
    setLocation(newUrl, { replace: true })
  }

  return (
    <Card borderWidth={isModal ? 0 : undefined} borderRadius={isModal ? 'md' : undefined} maxHeight="95vh">
      <CardHeader>
        <HStack>
          <VStack>
            <WouterLink to={defectPageUrl}>
              <Link>
                <VStack>
                  <HStack>
                    <Icon as={getDefectIcon(cwe_number ?? undefined)} boxSize={8} />
                    <HStack gap={0}>
                      <Heading variant="cardHeading">{title}</Heading>
                      <Icon as={RightArrowIcon} boxSize={8} />
                    </HStack>
                  </HStack>
                </VStack>
              </Link>
            </WouterLink>
          </VStack>
          <IconButton variant="outline" aria-label="close test case" icon={<CloseIcon />} onClick={closeDefectPreview} />
        </HStack>
      </CardHeader>
      <CardHeader>
        <HStack>
          <Text color="faded">Defect #{defectNumber}</Text>
          <DefectSeverityBadge severityLevel={severityLevel} severity={severity} />
          <DefectSeverityExplainer />
          <Feature name={FEATURE_LIFECYCLE_MANAGEMENT}>
            <DefectStatusBadge state={state} />
          </Feature>
        </HStack>
        <JiraIssue defectNumber={defectNumber} owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} />
      </CardHeader>
      <CardBody p={8} overflow="auto">
        <Skeleton isLoaded={!isDefectFetching}>
          <Stack gap={4} divider={<StackDivider />}>
            <Box p={2}>
              <Flex direction="row" alignItems="center" gap={4}>
                <Badge variant="secondary" marginX={4}>
                  <Link href={cwe_link} target="_blank" rel="noopener noreferrer">
                    CWE-{cwe_number}
                  </Link>
                </Badge>
                <Stack>
                  <Text fontSize="lg">{cwe_type}</Text>
                  <Text>{cwe_description}</Text>
                </Stack>
              </Flex>
            </Box>
            <Stack gap={4}>
              <Heading size="md">Example Test Case</Heading>
              <Skeleton isLoaded={!isFetchingExampleTestcaseReport && !isFetchingTestcaseReports}>
                <CodeDefectExampleTestcase
                  owner={owner}
                  projectSlug={projectSlug}
                  targetSlug={targetSlug}
                  runNumber={runNumber}
                  defectNumber={defectNumber}
                  exampleTestcaseReport={exampleTestcaseReport}
                  fromValgrind={!!fromValgrind}
                />
              </Skeleton>
            </Stack>
          </Stack>
        </Skeleton>
      </CardBody>
      <CardFooter justifyContent="end">
        <WouterLink to={defectPageUrl}>
          <Button rightIcon={<RightArrowIcon />}>View Full Defect Details</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}
