import { Heading, Stack } from '@chakra-ui/react'

import { TargetDefects } from './TargetDefects'
import { TargetOverview } from './TargetOverview'

import { CardDefectTrend } from '@/components/CardDefectTrend'
import { TargetRunsList } from '@/features/target-runs/TargetRunsList'

interface Props {
  workspace: string
  project: string
  target: string
  targetType: 'api' | 'code'
}

export function TargetOverviewPage({ workspace, project, target, targetType }: Props) {
  return (
    <Stack>
      <Stack>
        <TargetOverview owner={workspace} projectSlug={project} targetSlug={target} />
        <CardDefectTrend workspace={workspace} project={project} target={target} />
      </Stack>
      <Heading size="xl" margin={4} marginTop={12}>
        Defects
      </Heading>
      <TargetDefects owner={workspace} projectSlug={project} targetSlug={target} />
      <Heading size="xl" margin={4} marginTop={12}>
        Runs
      </Heading>
      <TargetRunsList owner={workspace} projectSlug={project} targetSlug={target} targetType={targetType} />
    </Stack>
  )
}
