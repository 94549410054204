import { CardBody, Table, TableContainer, Tbody } from '@chakra-ui/react'

import { WorkspaceInviteListItem } from './WorkspaceInviteListItem'

import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useGetAccountInvitesQuery } from '@/redux/api/invites'

import { MayhemPagination } from '@/components/MayhemPagination'

import { cleanInteger } from '@/util/numbers'

import { useQuery } from '@/hooks'

import ListDefaults from '@/components/ListDefaults'

import { TableCard } from '@/components/Card/TableCard'

interface WorkspaceInviteListProps {
  owner: string
}

const paginationPrefix = 'invites-'

export function WorkspaceInviteList({ owner }: WorkspaceInviteListProps) {
  const query = useQuery()
  const page = cleanInteger(query.get(`${paginationPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${paginationPrefix}perPage`), DEFAULT_PER_PAGE)

  const { currentData: invitesData, isFetching, isError } = useGetAccountInvitesQuery({ owner, perPage, offset: (page - 1) * perPage })

  const invitations = invitesData?.invitations.map((invitation) => (
    <WorkspaceInviteListItem key={invitation.account_invitation_id} owner={owner} {...invitation} />
  ))
  return (
    invitations &&
    invitations?.length > 0 && (
      <TableCard title="Pending User Invitations" CardBodyProps={{ sx: { paddingTop: 0 } }}>
        <CardBody>
          {!isFetching && invitations?.length > 0 && (
            <TableContainer>
              <Table>
                <Tbody>{invitations}</Tbody>
              </Table>
            </TableContainer>
          )}
          <ListDefaults isLoading={isFetching} nItems={invitations?.length} itemName="invitation" isFail={isError} />
        </CardBody>
        <MayhemPagination isLoading={isFetching} total={invitesData?.count || 0} prefix={paginationPrefix} />
      </TableCard>
    )
  )
}
