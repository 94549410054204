import { Select, SelectProps } from '@chakra-ui/react'

interface Props extends SelectProps {
  register?: object
}

export default function RoleSelect({ register, ...props }: Props) {
  return (
    <Select placeholder="Select a role..." {...register} variant="outline" {...props}>
      <option value="member">Member</option>
      <option value="owner">Owner</option>
    </Select>
  )
}
