import { Badge } from '@chakra-ui/react'

import { CapitalizedSeverity } from './DefectsTagV2'

export interface Props {
  severity: CapitalizedSeverity
  num_defects: number
}

export const DefectTableCell = ({ severity, num_defects }: Props) => (
  <Badge variant="subtle" colorScheme={`severity${severity}`} borderRadius="lg" minW="10" fontWeight="normal" textAlign="right" fontSize={14}>
    {num_defects}
  </Badge>
)
