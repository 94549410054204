import { useEffect } from 'react'

import { getWorkspacePageTitle } from '@/titles'

import { Page } from '@/components/Page'
import WorkspaceProjectsList from '@/features/workspace-projects/WorkspaceProjectsList'

type Props = { workspace: string }

export function OrganizationProjectsPage({ workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Projects')
  }, [workspace])

  return (
    <Page header="Projects">
      <WorkspaceProjectsList workspace={workspace} />
    </Page>
  )
}
