import React, { useCallback, useState } from 'react'

import { useLocation } from 'wouter'
import { IconButton, Input, InputGroup, InputGroupProps, InputRightElement } from '@chakra-ui/react'

import ClearSearchIcon from '@material-design-icons/svg/sharp/close.svg?react'

import debounce from 'lodash/debounce'

import { useQuery } from '../hooks'
import { setQueryParams } from '../util/location'
import { usePathname } from '../hooks.wouter'

interface Props {
  placeholder?: string
  width?: string
  InputGroupProps?: InputGroupProps
}

export const SEARCH_DEBOUNCE_TIME = 500

export function SearchBar({ placeholder, width, InputGroupProps }: Props) {
  const [location, setLocation] = useLocation()
  const pathname = usePathname()

  const query = useQuery()
  const currentQueryText = query.get('q') || ''
  const [inputValue, setInputValue] = useState(decodeURIComponent(currentQueryText))

  const searchRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    const queryText = event.target.value

    // set the search query param (or make it undefined if empty to clear the param)
    // and reset the page query param (since a new search should mean starting at the first page)
    setLocation(
      setQueryParams({
        location,
        queryParams: query,
        params: [
          { param: 'q', value: queryText?.length > 0 ? encodeURIComponent(queryText) : undefined },
          { param: 'page', value: undefined }
        ]
      }),
      { replace: true }
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(searchRequest, SEARCH_DEBOUNCE_TIME), [query, pathname])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    debouncedSearch(event)
  }

  const clearSearch = () => {
    setInputValue('')
    setLocation(
      setQueryParams({
        location,
        queryParams: query,
        params: [
          { param: 'q', value: undefined },
          { param: 'page', value: undefined }
        ]
      }),
      { replace: true }
    )
  }

  const extraProps = width
    ? { width }
    : (InputGroupProps ?? {
        maxWidth: ['100%', '100%', '80'],
        width: ['100%', '100%', '30%']
      })

  return (
    <InputGroup minWidth="9" size="md" {...extraProps}>
      <Input paddingY="2" paddingX="4" borderRadius={8} placeholder={placeholder} value={inputValue} onChange={onChange} />
      {inputValue.length > 0 && (
        <InputRightElement>
          <IconButton aria-label="Clear Search" icon={<ClearSearchIcon />} variant="ghost" colorScheme="gray" size="sm" onClick={clearSearch} />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
