import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'

import { getDocsUrl } from '../../urls'

import TextWithTooltip from '../../components/TextWithTooltip'
import OutsideLink from '../../components/OutsideLink'
import { useGetJiraSettingsQuery, usePutJiraSettingsMutation } from '../../redux/api/jira'

type Form = { atlassianEnabled: boolean; atlassianClientSecret?: string; atlassianClientId?: string }

export function AtlassianIntegrationSettingsCard(): JSX.Element {
  const toast = useToast()
  const { data: userSettings, isFetching: isEnrollmentConfigLoading } = useGetJiraSettingsQuery()
  const [updateUserSettings] = usePutJiraSettingsMutation()

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting }
  } = useForm<Form>({
    defaultValues: {
      atlassianClientId: '',
      atlassianClientSecret: '',
      atlassianEnabled: false
    }
  })

  useEffect(() => {
    if (!userSettings) {
      return
    }
    setValue('atlassianClientId', userSettings.atlassian_oauth_client_id || '')
    setValue('atlassianEnabled', userSettings.atlassian_enabled || false)
  }, [userSettings, setValue])

  const [isClientSecretHidden, setIsClientSecretHidden] = useState(true)
  const currentAtlassianEnabledValue = watch('atlassianEnabled')

  const _updateAtlassianConfig = async (atlassianConfig: Form) => {
    if (!userSettings) {
      return
    }
    try {
      await updateUserSettings({
        putJiraSettings: {
          atlassian_enabled: atlassianConfig.atlassianEnabled,
          atlassian_oauth_client_id: atlassianConfig.atlassianClientId,
          atlassian_oauth_client_secret: atlassianConfig.atlassianClientSecret
        }
      }).unwrap()
      toast({
        title: 'User Account creation settings updated.',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
    } catch (e) {
      toast({
        title: 'Failed to update user account creation settings.',
        status: 'error',
        duration: 6000,
        isClosable: true
      })
    }
  }

  const atlassianLabel = <Text>Atlassian Integration</Text>

  const helpText = (
    <Stack>
      <Text>Mayhem integrates with your Jira instance to add tickets for defects found.</Text>
      <Text>
        To learn more, check out our docs on{' '}
        <OutsideLink href={getDocsUrl('integrations/integration/jira/')}>Setting up a Jira Integration</OutsideLink>.
      </Text>
    </Stack>
  )

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">
          Jira Integration Settings
          <TextWithTooltip tooltip={helpText}></TextWithTooltip>
          {isEnrollmentConfigLoading && <Spinner size="sm" />}
        </Heading>
      </CardHeader>
      <CardBody p={8}>
        <form onSubmit={handleSubmit(_updateAtlassianConfig)}>
          <Stack spacing={4}>
            <FormControl display="flex" flexDirection="row" width="max-content">
              <FormLabel gridRow={1}>{atlassianLabel}</FormLabel>
              <Controller
                name="atlassianEnabled"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Switch isChecked={value} onChange={onChange} name="atlassianEnabled" aria-label="enable atlassian integration" ref={ref} />
                )}
              />
            </FormControl>
            {currentAtlassianEnabledValue && (
              <>
                <FormControl>
                  <FormLabel>Atlassian OAuth Client ID</FormLabel>
                  <Controller name="atlassianClientId" control={control} render={({ field }) => <Input {...field} />} />
                </FormControl>
                <FormControl>
                  <FormLabel>Atlassian OAuth Client Secret</FormLabel>
                  <Controller
                    name="atlassianClientSecret"
                    control={control}
                    render={({ field }) => (
                      <InputGroup size="md">
                        <Input {...field} pr="4.5rem" type={!isClientSecretHidden ? 'text' : 'password'} />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={() => setIsClientSecretHidden(!isClientSecretHidden)}>
                            {!isClientSecretHidden ? 'Hide' : 'Show'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  <FormHelperText> This field is write only </FormHelperText>
                </FormControl>
              </>
            )}
          </Stack>
          <Box display="flex" justifyContent="flex-end" marginTop="4">
            <Button type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </Box>
        </form>
      </CardBody>
    </Card>
  )
}
