import { useEffect } from 'react'

import { Heading } from '@chakra-ui/react'
import { Redirect } from 'wouter'

import { getWorkspacePageTitle } from '../../titles'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'
import { Page } from '../../components/Page'
import { useGetAccountStatsQuery } from '../../redux/api/workspace'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { WorkspaceNonZeroDashboard } from './WorkspaceNonZeroDashboard'

type Props = { workspace: string }

export function WorkspaceDashboardPage({ workspace }: Props) {
  const { isLoading, isError, data } = useGetAccountStatsQuery({
    owner: workspace
  })

  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Dashboard')
    /* TODO ideally
    dispatch(setMruWorkspace(workspace))
    // here, but, currently, if user had a typo in URL, we'll store the typo :(
    // so, setting workspace.mru in the "next step" instead.
    */
  }, [workspace])

  if (isError) {
    return <ErrorPage errorMessage={`We can't find that workspace for you. Sorry about that!`} goBackLocation="/" />
  }

  const renderContent = () => {
    if (isLoading) {
      return <MayhemPageLoader />
    }

    if (data?.project_count && data?.project_count > 0) {
      return <WorkspaceNonZeroDashboard workspace={workspace} />
    }

    return <Redirect to={`/${workspace}/-/getting-started`} />
  }

  return <Page header={<Heading>Dashboard</Heading>}>{renderContent()}</Page>
}
