import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import UserService from '../../features/auth/UserService'

import { RootState } from '../store'
import { getCookie } from '../../cookies'

export const AUTOREFRESH_INTERVAL = 5000
export const DEFAULT_PER_PAGE = 15
export const DEFAULT_PER_PAGE_OPTIONS = [15, 30, 45]

const mayhemApiBase = fetchBaseQuery({
  baseUrl: import.meta.env.VITEST === 'true' ? new URL('/', location.origin).href : '/',
  credentials: 'same-origin',
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState
    if (state.config.apiVersion) {
      headers.set('X-Mayhem-Client-Version', state.config.apiVersion)
    }

    // Ignore this block when running in test mode. We do not unit
    // test anything that requires keycloak at time of writing.
    const FAIL_AFTER_N_ATTEMPTS = 100
    if (import.meta.env.VITEST !== 'true') {
      let attempts = 0
      // We cannot get a valid keycloak state from UserService unless we know
      // whether keycloak has been initialized or not. We cannot initialize
      // keycloak in here since keycloak state must only be initialized once for
      // the entire app. It is assumed that this initialization is handled at the
      // top level of the App itself.
      const config = (getState() as RootState).config
      while (config.keycloakEnabled && config.keycloakInitialized === undefined) {
        if (attempts >= FAIL_AFTER_N_ATTEMPTS) {
          throw Error(`Keycloak state was not set after waiting ${attempts} iterations.`)
        }
        attempts = attempts + 1
        await sleep(50)
      }
    }

    if (UserService.isLoggedInToKeycloak()) {
      // This is usually a no-op if user's token is still valid - otherwise
      // this will automatically refresh the token before using it in the header.
      await UserService.updateKeycloakToken()
      if (UserService.getKeycloakBearerToken() !== undefined) {
        headers.set('Authorization', `Bearer ${UserService.getKeycloakBearerToken()}`)
      }
    }

    return headers
  }
})

async function sleep(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec))
}
const mayhemApiBaseWithCsrfAndRedirectOn401: typeof mayhemApiBase = async (arg, ...args) => {
  if (typeof arg === 'object' && typeof arg.body === 'object' && ['POST', 'PUT'].indexOf(arg.method as string) !== -1) {
    arg.body.token = getCookie('token')
  }
  const result = await mayhemApiBase(arg, ...args)

  const getState = args[0]?.getState
  const rootState = getState && getState()
  let keycloakEnabled: undefined | boolean = false
  if (rootState) {
    keycloakEnabled = (rootState as RootState).config?.keycloakEnabled
  }

  // if the server responds with a 401 response, redirect to the sign-in page
  // with the referrer url set
  if (result.error && result.error.status === 401) {
    const referrerUrl = `${window.location.pathname}${window.location.search}`
    if (keycloakEnabled) {
      UserService.doLogin()
    } else if (window.location.pathname !== '/-/sign-in') {
      const to = `/-/sign-in?referrer=${encodeURIComponent(referrerUrl)}`
      window.location.replace(to)
    }
  }

  return result
}

export const mayhemApi = createApi({
  reducerPath: 'mayhemApi',
  baseQuery: mayhemApiBaseWithCsrfAndRedirectOn401,
  endpoints: () => ({})
})
