import { useEffect } from 'react'

import { Badge, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { TargetOverviewPage } from './TargetOverviewPage'

import { TargetSettingsGeneralPage } from './TargetSettingsGeneralPage'

import { DataTab, DataTabs } from '@/components/DataTabs'
import { ErrorPanel } from '@/components/ErrorPanel'
import MayhemTableLoader from '@/components/MayhemTableLoader'
import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'
import { useAppSelector } from '@/hooks'
import { useGetProjectMemberPermissionQuery } from '@/redux/api/projects'
import { useGetTargetQuery } from '@/redux/api/targets'
import { getTargetPageTitle } from '@/titles'

import { getCurrentUserInfo } from '@/features/auth/utils'
import { WorkersFullNotice } from '@/features/runs/WorkersFullNotice'

interface Props {
  workspace: string
  project: string
  target: string
}

export function TargetPage({ workspace, project, target }: Props) {
  useEffect(() => {
    document.title = getTargetPageTitle(workspace, project, target)
  }, [workspace, project, target])

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspace,
    projectSlug: project,
    userSlug
  })
  const isAdmin = isMayhemAdmin || (!projectMemberPermissionIsLoading && projectPermission && projectPermission.permission === 'ADMIN')

  const { data: targetData, isLoading } = useGetTargetQuery({
    owner: workspace,
    projectSlug: project,
    targetSlug: target
  })
  const { target_name: targetName, is_deleted: isDeleted, type: targetType } = targetData || {}

  if (isLoading) {
    return <MayhemTableLoader />
  }

  if (!targetData || isDeleted) {
    return <ErrorPanel msg="We can't find that target for you. Sorry about that!" />
  }

  const readTabs: DataTab[] = [
    {
      label: 'Overview',
      content: <TargetOverviewPage workspace={workspace} project={project} target={target} targetType={targetType as 'api' | 'code'} />,
      route: `/${workspace}/${project}/${target}`
    }
  ]

  const adminTabs = [
    {
      label: 'Settings',
      content: <TargetSettingsGeneralPage workspace={workspace} project={project} target={target} />,
      route: `/${workspace}/${project}/${target}/-/settings/general`
    }
  ]

  return (
    <Page header={<TargetBreadcrumb workspace={workspace} project={project} target={target} />}>
      <WorkersFullNotice />
      <Heading mb={8}>
        {targetName}
        <Badge ml={4} key={targetType} variant="outline">
          {targetType}
        </Badge>
      </Heading>
      <DataTabs isLazy variant="line" width="full" tabs={isAdmin ? readTabs.concat(adminTabs) : readTabs} />
    </Page>
  )
}

export interface TargetBreadcrumbProps {
  workspace: string
  project: string
  target: string
}

export const TargetBreadcrumb = ({ workspace, project, target }: TargetBreadcrumbProps) => {
  return (
    <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
      <BreadcrumbItem>
        <WouterLink to={`/${workspace}/${project}`}>
          <BreadcrumbLink>
            <TruncatedTextWithTooltip text={project} />
          </BreadcrumbLink>
        </WouterLink>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink>
          <TruncatedTextWithTooltip text={target} />
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
