import { Button, Card, CardBody, CardHeader, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, useDisclosure } from '@chakra-ui/react'

import { useState } from 'react'

import { UserPermissionsTable } from './UserPermissionsTable'
import { TeamPermissionsTable } from './TeamPermissionsTable'
import { UserPermissionsModal } from './UserPermissionsModal'
import { TeamPermissionsModal } from './TeamPermissionsModal'

interface Props {
  owner: string
  projectSlug: string
  canManageTeams: boolean
  isWorkspaceLoading: boolean
  isAtLeastWritePermission: boolean
}

export default function PermissionsList({ owner, projectSlug, canManageTeams, isWorkspaceLoading, isAtLeastWritePermission }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Card>
      <CardHeader paddingBottom={2} paddingLeft={5}>
        <Heading variant="cardHeading">Contributors</Heading>
        {isAtLeastWritePermission && (
          <Button borderRadius={8} onClick={onOpen}>
            Add {tabIndex === 0 ? 'User' : 'Team'}
          </Button>
        )}
      </CardHeader>
      <CardBody padding={4}>
        <Tabs variant="enclosed" isLazy width="full" tabIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab fontWeight={600}>Users</Tab>
            <Tab isDisabled={isWorkspaceLoading || !canManageTeams} fontWeight={600}>
              <Tooltip
                isDisabled={canManageTeams}
                label={`The project "${projectSlug}" is a part of the ${owner} personal account. Teams can only be created and added to projects belonging to organizations.`}
              >
                Teams
              </Tooltip>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UserPermissionsTable workspace={owner} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
            <TabPanel>
              <TeamPermissionsTable workspace={owner} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
      <UserPermissionsModal workspace={owner} projectSlug={projectSlug} open={tabIndex === 0 && isOpen} onClose={onClose} />
      <TeamPermissionsModal workspace={owner} projectSlug={projectSlug} open={tabIndex === 1 && isOpen} onClose={onClose} />
    </Card>
  )
}
