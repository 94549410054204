import React, { useEffect } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, HStack, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react'
import OpenInNewIcon from '@material-design-icons/svg/sharp/open_in_new.svg?react'
import { useLocation } from 'wouter'

import { useGetUserSettingQuery, useWhoamiQuery } from '../../redux/api/workspace'

import UserService from '../auth/UserService'
import { getOwnerSettingsPageTitle } from '../../titles'
import { useAppDispatch } from '../../hooks'
import { logout } from '../auth/actions'

import DarkModeToggle from '../../components/DarkModeToggle'

import { DeleteUserModal } from './DeleteUserModal'
import { UpdateInfoForm } from './UpdateInfoForm'
import { UpdatePasswordForm } from './UpdatePasswordForm'

export function UserSettingsPersonalInfoPage() {
  const dispatch = useAppDispatch()
  const [_, setLocation] = useLocation()

  const { isOpen: isDeleteUserModalOpen, onOpen: onOpenDeleteUserModal, onClose: onCloseDeleteUserModal } = useDisclosure()

  const { data: userSettings, isLoading: isFetchingUserSettings } = useGetUserSettingQuery()
  const { isLoading: isFetchingUser, data: profile } = useWhoamiQuery()
  const keycloakEnabled = userSettings?.keycloak_enabled
  const currentUserSlug = profile?.slug

  useEffect(() => {
    document.title = getOwnerSettingsPageTitle(currentUserSlug || '', 'My Details')
  }, [dispatch, currentUserSlug])

  // Keycloak-sourced accounts are not treated the same as generic OAuth
  // accounts (Google/Okta/GitHub native integration).
  const keycloakAccountUrl = !!keycloakEnabled && UserService.accountManagementUrl()

  const handleLogout = () => {
    dispatch(logout()).then(() => setLocation('/-/sign-in', { replace: true }))
  }

  if (isFetchingUser || isFetchingUserSettings) {
    return <Skeleton height={80} />
  }

  return (
    <Stack>
      {keycloakEnabled ? (
        // Keycloak-specific profile card
        <Card mb={8}>
          <CardHeader>
            <Heading variant="cardHeading" m={0}>
              Your Profile
            </Heading>
            <Button onClick={handleLogout}>Logout</Button>
          </CardHeader>
          <CardBody p={8}>
            <UpdateInfoForm />
          </CardBody>
          <CardHeader>
            <Heading variant="cardHeading">Advanced</Heading>
          </CardHeader>
          <CardBody p={8}>
            {keycloakAccountUrl && (
              <a href={keycloakAccountUrl} target="_blank" rel="noopener noreferrer">
                <Button leftIcon={<OpenInNewIcon />} data-selenium-id="viewAllRuns">
                  Advanced Account Settings
                </Button>
              </a>
            )}
          </CardBody>
        </Card>
      ) : (
        // Not Keycloak profile card
        <React.Fragment>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading" m={0}>
                Your Profile
              </Heading>
              <Button onClick={handleLogout}>Logout</Button>
            </CardHeader>
            <CardBody p={8}>
              <UpdateInfoForm />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Update Password</Heading>
            </CardHeader>
            <CardBody p={8}>{currentUserSlug && <UpdatePasswordForm />}</CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Display Settings</Heading>
            </CardHeader>
            <CardBody p={8}>
              <HStack spacing={8} alignItems="start">
                <Text>Light/Dark Mode:</Text> <DarkModeToggle />
              </HStack>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Danger Zone</Heading>
            </CardHeader>
            <CardBody p={8}>
              {currentUserSlug && (
                <DeleteUserModal
                  userSlug={currentUserSlug}
                  open={isDeleteUserModalOpen}
                  onOpen={onOpenDeleteUserModal}
                  onClose={onCloseDeleteUserModal}
                />
              )}
              <Button colorScheme="red" onClick={onOpenDeleteUserModal}>
                Delete my account
              </Button>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </Stack>
  )
}
