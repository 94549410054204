import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(duration)

export const DATE_PERIODS = ['All Time', 'Today', 'Past Week', 'Past Month']

/**
 * Alias for Dayjs. Allows us to switch date library.
 */
export type MayhemDate = Dayjs

export function parseApiDate(date: string): Dayjs
export function parseApiDate(date: string | null): Dayjs | null
export function parseApiDate(date: string | undefined): Dayjs | undefined
export function parseApiDate(date: string | null | undefined): Dayjs | null | undefined {
  if (date === null) {
    return null
  }
  if (date === undefined) {
    return undefined
  }
  return dayjs(date)
}

/**
 * Returns how long ago the provided date was from now.
 * e.g. "4 hours ago"
 * @param {Dayjs} date - the target date in ms
 * @returns {string} the "X time ago" string
 */
export function formatAgo(date: Dayjs): string
export function formatAgo(date: Dayjs | null): string | null
export function formatAgo(date: Dayjs | undefined): string | undefined
export function formatAgo(date: Dayjs | null | undefined): string | null | undefined {
  if (date === null) {
    return null
  }
  if (date === undefined) {
    return undefined
  }
  return date.fromNow()
}

/**
 * Check if timezone is valid
 * e.g. America/Toronto
 * @param {string} timezone
 * @returns {boolean}
 */
export function isTimezoneValid(timezone: string): boolean {
  try {
    dayjs().tz(timezone)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Returns time string in the format "<date>, <time> <timezone>".
 * e.g. 1/1/2020, 1:11:11 PM EDT
 * If retrieved timezone is not valid, omit <timezone>
 * @param {Dayjs} datetime - the time in ms you want to format.
 * @returns {string} the fully formatted date/time string
 */
export function formatDatetime(datetime: Dayjs): string
export function formatDatetime(datetime: Dayjs | null): string | null
export function formatDatetime(datetime: Dayjs | undefined): string | undefined
export function formatDatetime(datetime: Dayjs | undefined | null): string | null | undefined {
  if (datetime === undefined || datetime === null) {
    return datetime
  }

  const timezone = guessBrowserTz()

  if (isTimezoneValid(timezone)) {
    return datetime.format('LL LTS z')
  }

  return datetime.format('LL LTS')
}

/**
 * Returns time string in the format "<date>".
 * e.g. 1/1/2020
 * @param {Dayjs} datetime - the time in ms you want to format.
 * @returns {string} the fully formatted date/time string
 */
export function formatDate(datetime: Dayjs): string
export function formatDate(datetime: Dayjs | null): string | null
export function formatDate(datetime: Dayjs | undefined): string | undefined
export function formatDate(datetime: Dayjs | undefined | null): string | null | undefined {
  if (datetime === undefined || datetime === null) {
    return datetime
  }

  return datetime.format('L')
}

/**
 * Returns time string in the format "<time> <timezone>".
 * e.g. 1:11:11 PM EDT
 * @param {Dayjs} datetime - the time in ms you want to format.
 * @returns {string} the fully formatted date/time string
 */
export function formatTimeAndTz(datetime: Dayjs): string
export function formatTimeAndTz(datetime: Dayjs | null): string | null
export function formatTimeAndTz(datetime: Dayjs | undefined): string | undefined
export function formatTimeAndTz(datetime: Dayjs | undefined | null): string | null | undefined {
  if (datetime === undefined || datetime === null) {
    return datetime
  }

  return datetime.format('LTS z')
}

/**
 * Adds leading 0 to time if necessary. For use in timestamps.
 * e.g. 9 => 09
 * @param {number} time - int value of time.
 * @returns {string} string of the provided number with a leading zero added (if needed)
 */
export function padTime(time: number): string {
  return time > 9 ? `${time}` : `0${time}`
}

/**
 * Returns elapsed time string in the format "<years>Y <months>M <days>:<hours>:<minutes>:<seconds>".
 * e.g. an elapsed time of 4 hours, 3 min, 14 sec => 04:03:14
 * e.g. an elapsed time of 1 year, 4 hours, 3 min, 14 sec => 1Y 0M 04:03:14
 * Hides leading units when not needed.
 * @param {number} secs - elapsed time in seconds.
 * @returns {string} the timestamp style time string
 */
export function prettyTimeElapsedStamp(secs: number): string {
  const duration = dayjs.duration(secs * 1000)

  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const yearsLabel = years > 0 ? `${years}Y ` : ''
  const monthsLabel = years > 0 || months > 0 ? `${months}M ` : ''
  const daysLabel = days > 0 ? `${padTime(days)}:` : ''
  const hoursLabel = years > 0 || months > 0 || days > 0 || hours > 0 ? `${padTime(hours)}:` : ''
  const minutesLabel = `${padTime(minutes)}:`
  const secondsLabel = `${padTime(seconds)}`

  return `${yearsLabel}${monthsLabel}${daysLabel}${hoursLabel}${minutesLabel}${secondsLabel}`
}

/**
 * Adds an 's' to the end of a label string based on the amount.
 * @param {string} label - label to possibly pluralize.
 * @param {number} value - the value being labelled.
 * @returns {string} the (if needed) pluralized label
 */
function pluralLabel(label: string, value: number): string {
  return `${label}${value === 1 ? '' : 's'}`
}

/**
 * Returns elapsed time string in the format "<years> years <months> months <days> days <days> hours <minutes> minutes <seconds> seconds".
 * Hides leading units when not needed. Pluralizes as appropriate.
 * @param {number} secs - elapsed time in seconds.
 * @param {boolean} short - if enabled cuts minutes and seconds.
 * @returns {string} the expanded style time string
 */
export function prettyTimeElapsedString(secs: number, short = false): string {
  const duration = dayjs.duration(secs * 1000)
  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const yearsLabel = years > 0 ? `${years} ${pluralLabel('year', years)}` : ''
  const monthsLabel = years > 0 || months > 0 ? `${months} ${pluralLabel('month', months)}` : ''
  const daysLabel = years > 0 || months > 0 || days > 0 ? `${days} ${pluralLabel('day', days)}` : ''
  const hoursLabel = years > 0 || months > 0 || days > 0 || hours > 0 ? `${hours} ${pluralLabel('hr', hours)}` : ''
  const minutesLabel = minutes > 0 || !short ? `${minutes} min` : ''
  const secondsLabel = seconds > 0 || !short ? `${seconds} sec` : ''

  return [yearsLabel, monthsLabel, daysLabel, hoursLabel, minutesLabel, secondsLabel].filter((label) => !!label).join(' ')
}

/**
 * Uses the dayjs fromNow to return how long ago the provided date was from now
 * e.g. "4 hours ago"
 * @param {Dayjs | null | undefined} date - the target date in ms
 * @returns {string} the "X time ago" string
 */
export function prettyTimeAgo(date: Dayjs | undefined | null): string {
  if (!date) {
    return dayjs().fromNow()
  }
  return date.fromNow()
}

export function guessBrowserTz(): string {
  return dayjs.tz.guess()
}

/**
 * Returns time string in the format "<seconds>s <mills>ms"
 * e.g. 8s 320ms
 *
 * @param {number} secs number of seconds to format (can be floating point).
 * @returns {string} the fully formatted string
 */
export function prettyTime(secs: number): string {
  const millis = Math.trunc(secs * 1000)

  return `${millis}ms`
}

/**
 * Returns time string in the format "<date>, <time> <timezone>"
 * e.g. 1/1/2020, 1:11:11 PM EDT
 * @param {number} datetime - the time in ms you want to format.
 * @returns {number} the fully formatted date/time string
 */
export function prettyFullTimeString(datetime: number | string): string {
  return `${new Date(datetime).toLocaleString(undefined, {
    dateStyle: 'long',
    timeStyle: 'long'
  })}`
}
