import { Heading, Stack } from '@chakra-ui/react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

const section = (title: string, content?: string | number) => {
  if (!content) {
    return <></>
  }
  return (
    <Stack>
      <Heading size="md">{title}</Heading>
      <CodeBlock>{content.toString()}</CodeBlock>
    </Stack>
  )
}

export default function TestCaseTriageReport({
  sha256,
  disassembly,
  output,
  maps,
  registers,
  backtrace,
  asanOutput,
  signalNumber,
  faultingAddress,
  hexdump
}: Props): JSX.Element {
  return (
    <Stack spacing={4}>
      {section('Test Case File Name', sha256)}
      {section('Test Case', hexdump)}
      {section('ASAN Output', asanOutput)}
      {section('Output', output)}
      {section('Backtrace', backtrace)}
      {section('Disassembly', disassembly)}
      {section('Faulting Address', faultingAddress)}
      {section('Register State', registers)}
      {section('Signal Number', signalNumber)}
      {section('Memory Maps', maps)}
    </Stack>
  )
}

interface Props {
  sha256?: string
  disassembly?: string
  output?: string
  maps?: string
  registers?: string
  backtrace?: string
  asanOutput?: string
  signalNumber?: string | number
  faultingAddress?: string
  hexdump?: string
}
