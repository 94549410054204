import { HStack } from '@chakra-ui/react'

import { useUpdateQueryParams } from '../../util/location'

import { DefectsTagV2 } from './DefectsTagV2'

import { SeverityCategory, categoryFromScore } from '@/features/run-api-defects/runApiDefects.slice'

export interface RunDefectsSeverityBreakdownProps {
  high: number
  medium: number
  low: number
}

export const RunDefectsSeverityBreakdown = ({ high, medium, low }: RunDefectsSeverityBreakdownProps) => {
  const updateQueryParams = useUpdateQueryParams()

  return (
    <HStack spacing={8}>
      <DefectsTagV2 severity="High" tagLabel="H" count={high} onClick={() => updateQueryParams({ severity: 'high' })} />

      <DefectsTagV2 severity="Medium" tagLabel="M" count={medium} onClick={() => updateQueryParams({ severity: 'medium' })} />

      <DefectsTagV2 severity="Low" tagLabel="L" count={low} onClick={() => updateQueryParams({ severity: 'low' })} />

      <DefectsTagV2 severity="Total" tagLabel="Total" count={high + medium + low} onClick={() => updateQueryParams({ severity: undefined })} />
    </HStack>
  )
}

export const LabeledDefectTagNum = ({ severityNumber }: { severityNumber: number }) => {
  switch (categoryFromScore(severityNumber)) {
    case SeverityCategory.High:
      return <DefectsTagV2 severity="High" count={severityNumber} />
    case SeverityCategory.Medium:
      return <DefectsTagV2 severity="Medium" count={severityNumber} />
  }
  return <DefectsTagV2 severity="Low" count={severityNumber} />
}
