import { defineStyle } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const styles = {
  global: {
    html: {
      fontSize: '14px'
    },
    body: {
      bg: mode('white', 'gray.900')
    },
    // rc-pagination renders elements with css classes.
    // chakra recommends styling external components in this fashion
    // https://chakra-ui.com/docs/styled-system/global-styles#styling-non-chakra-elements-globally
    '.rc-pagination': defineStyle((props) => {
      return {
        // list container styling
        margin: 0,
        padding: 0,
        listStyle: 'none',
        '> li': {
          display: 'inline-block'
        },
        // ensure each button has overlapping borders
        // see chakra's button group for inspiration
        // https://chakra-ui.com/docs/components/button/usage#grouping-buttons
        '> li > *': {
          marginInlineEnd: '-1px'
        },
        // used from chakra button's gray theme
        // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts#L29
        '> .rc-pagination-item-active > a': {
          color: mode(`gray.800`, `whiteAlpha.900`)(props),
          backgroundColor: mode(`gray.200`, `whiteAlpha.300`)(props)
        },
        // tweak border radii, inspired by chakra's button group
        // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/button/src/button-group.tsx#L18
        '> *:not(:is(.rc-pagination-options)):first-of-type:not(:last-of-type) > *': {
          borderEndRadius: 0
        },
        '> *:not(:is(.rc-pagination-options, .rc-pagination-next)):not(:first-of-type):not(:last-of-type) > *': {
          borderRadius: 0
        },
        '> .rc-pagination-next > *': {
          borderStartRadius: 0,
          borderEndRadius: 'var(--chakra-radii-md)'
        }
      }
    })
  }
}
