import { useLocation } from 'wouter'

import { FilterDropdown, FilterDropdownItem } from '@/components/Combobox/FilterDropdown'
import { useQuery } from '@/hooks'
import { setQueryParams } from '@/util/location'

interface Props {
  inputItems: FilterDropdownItem[]
  defaultInputPlaceholder: string
  param: string
  multiSelect?: boolean
}

export default function QueryParamFilterDropdown({ inputItems, defaultInputPlaceholder, param, multiSelect = false }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const paramValue = query.get(param)
  const selectedItemIds = paramValue ? paramValue.split(',') : []
  const selectedItems = inputItems.filter(({ id }) => selectedItemIds.includes(id))

  const handleSelectedItemsChange = (items: FilterDropdownItem[]) => {
    const newParamValue = items.map(({ id }) => id).join(',') || undefined
    setLocation(
      setQueryParams({
        location,
        queryParams: query,
        params: [
          { param: 'page', value: undefined },
          { param, value: newParamValue }
        ]
      }),
      { replace: true }
    )
  }

  return (
    <FilterDropdown
      selectedItems={selectedItems}
      onSelectedItemsChange={handleSelectedItemsChange}
      inputItems={inputItems}
      defaultInputPlaceholder={defaultInputPlaceholder}
      multiSelect={multiSelect}
    />
  )
}
