import { ComponentStyleConfig } from '@chakra-ui/react'

export const Form: ComponentStyleConfig = {
  parts: ['helperText'],
  baseStyle: () => ({
    helperText: {
      fontSize: 'md'
    }
  })
}
