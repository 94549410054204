import { Box, Tooltip } from '@chakra-ui/react'

import { ProgressIndicator } from './ProgressIndicator/ProgressIndicator'

export enum Result {
  PASS = 'pass',
  FAIL = 'fail',
  NONE = 'none'
}

export default function PassFailCheck({ result = Result.NONE }: { result: Result }): JSX.Element {
  return (
    <Tooltip label={result === Result.PASS ? 'Check Passed' : result === Result.FAIL ? 'Check Failed' : 'Check Not Performed'}>
      <Box>
        <ProgressIndicator variant={result === Result.PASS ? 'ended' : result === Result.FAIL ? 'failed' : 'unknown'} size="sm" />
      </Box>
    </Tooltip>
  )
}
