import React, { useCallback, useRef, useState } from 'react'

import { Button, IconButton, IconButtonProps, Tooltip, useToast } from '@chakra-ui/react'
import DownloadIcon from '@material-design-icons/svg/sharp/download.svg?react'

import { head } from '../api'

const downloadFailureText = 'There was an error downloading this file at this time.  Please try again later.'

interface Props {
  href: string
  download?: unknown
  label?: string
}

type Status = 'Initial' | 'Success' | 'Failure' | 'Loading'

export function DownloadFileButton({ href, label, download, ...props }: Props & IconButtonProps) {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const toast = useToast()
  const [status, setStatus] = useState<Status>('Initial')

  const handleFailure = useCallback(() => {
    setStatus('Failure')
    toast({
      title: 'Download Failed',
      description: 'There was an error downloading this file at this time.  Please try again later.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [toast, setStatus])

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (props.isDisabled) {
        e.preventDefault()
        return
      }
      if (status === 'Loading') {
        // e.preventDefault()
        return
      }

      if (status !== 'Success') {
        setStatus('Loading')
        head(href)
          .then((resp) => {
            if (resp === true) {
              setStatus('Success')

              if (linkRef.current) {
                linkRef.current.click()
              }
            } else {
              handleFailure()
            }
          })
          .catch(() => {
            handleFailure()
          })
        e.preventDefault()
      }
    },
    [href, status, setStatus, handleFailure, props.isDisabled]
  )

  return (
    <React.Fragment>
      <Tooltip label={downloadFailureText} isDisabled={status !== 'Failure'}>
        {label ? (
          <Button
            {...props}
            ref={linkRef}
            as="a"
            onClick={handleButtonClick}
            isLoading={status === 'Loading'}
            download={download}
            href={href}
            leftIcon={<DownloadIcon />}
            iconSpacing={!label ? 0 : undefined}
          >
            {label}
          </Button>
        ) : (
          <IconButton
            {...props}
            ref={linkRef}
            as="a"
            href={href}
            size="lg"
            icon={<DownloadIcon />}
            onClick={handleButtonClick}
            isLoading={status === 'Loading'}
            download={download}
          />
        )}
      </Tooltip>
    </React.Fragment>
  )
}
