import { Button, Card, CardBody, CardHeader, Heading, useDisclosure, useToast } from '@chakra-ui/react'

import { useCallback } from 'react'
import { useLocation } from 'wouter'

import { useDeleteOrgMutation } from '../../redux/api/workspace'

import { getMessageFromRtkError } from '../../util/errors'

import WorkspaceDeleteModal from './WorkspaceDeleteModal'

type Props = {
  owner: string
  workspace: string
}

export function DangerZoneCard({ owner, workspace }: Props) {
  const [_location, setLocation] = useLocation()
  const toast = useToast()
  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteWorkspace] = useDeleteOrgMutation()
  const onDeleteWorkspace = useCallback(async () => {
    try {
      toast({
        title: 'Workspace Deleted',
        description: `${workspace} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${owner}`) // Personal workspace cannot be deleted - safe to go here
      await deleteWorkspace({ orgSlug: workspace }).unwrap()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Workspace Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteWorkspace, owner, workspace, setLocation])

  return (
    <Card>
      <CardHeader paddingBottom={0} paddingX={5}>
        <Heading variant="cardHeading">Danger Zone</Heading>
      </CardHeader>
      <CardBody p={8}>
        <Button colorScheme="red" onClick={onDeleteConfirmationOpen}>
          Delete Workspace
        </Button>
      </CardBody>
      {isDeleteConfirmationOpen && owner && workspace && (
        <WorkspaceDeleteModal
          workspace={workspace}
          deleteHandler={onDeleteWorkspace}
          closeHandler={onDeleteConfirmationClose}
          open={isDeleteConfirmationOpen}
        />
      )}
    </Card>
  )
}
