import React from 'react'

import { Box, Card, CardBody, CardHeader, Heading, SkeletonText, Text } from '@chakra-ui/react'

import { prettyTimeElapsedString } from '../../util/time'
import { useGetUserSettingQuery } from '../../redux/api/workspace'
import { ErrorPanel } from '../../components/ErrorPanel'

import { MayhemBotSettingsForm } from './MayhemBotSettingsForm'

const MayhemBotIntro = () => {
  const { data: userSetting, isLoading, isError } = useGetUserSettingQuery()

  const isMbotUtilizationDisabled = userSetting?.mbot_utilization === 0

  if (isError) {
    return <ErrorPanel msg="Something went wrong loading mayhem bot settings. Sorry about that!" />
  }

  if (!userSetting || isLoading) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Text>Currently&nbsp;{isMbotUtilizationDisabled ? <strong>disabled</strong> : <strong>enabled</strong>}</Text>
      <Text>
        Set the maximum number of workers to make available for Mayhem&apos;s continuous testing service. With this service, Mayhem will help you
        maximize the testing of your targets by automatically starting Behavior Testing runs (with a duration of{' '}
        <strong>{prettyTimeElapsedString(userSetting.mbot_task_duration || 0, true)}</strong>) on idle targets.
      </Text>
    </React.Fragment>
  )
}

export default function MayhemBotCard(): JSX.Element {
  return (
    <Card h="100%">
      <CardHeader>
        <Heading variant="cardHeading">Automatic Behavior Testing Service</Heading>
      </CardHeader>
      <CardBody p={8}>
        <MayhemBotIntro />
        <MayhemBotSettingsForm />
      </CardBody>
    </Card>
  )
}
