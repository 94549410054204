import { useEffect } from 'react'
import { Card, CardHeader, Heading, Button, CardBody } from '@chakra-ui/react'
import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

import { Link } from 'wouter'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetOrgsByUserQuery, useWhoamiQuery } from '../../redux/api/workspace'
import { useQuery } from '../../hooks'
import { getOwnerSettingsPageTitle } from '../../titles'
import { cleanInteger } from '../../util/numbers'

import { WorkspaceRow } from '../workspace/WorkspaceRow'

const paginationPrefix = 'workspaces-'

export function UserWorkspacesPage() {
  const { data: user } = useWhoamiQuery()
  const query = useQuery()
  const page = cleanInteger(query.get(`${paginationPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${paginationPrefix}perPage`), DEFAULT_PER_PAGE)

  const {
    isFetching,
    isError,
    currentData: workspaces
  } = useGetOrgsByUserQuery(
    {
      userSlug: user?.slug || '',
      perPage,
      offset: (page - 1) * perPage
    },
    { skip: !user }
  )

  useEffect(() => {
    document.title = getOwnerSettingsPageTitle(user?.slug || '', 'Workspaces')
  }, [user])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Workspaces</Heading>
        <Button as={Link} to="/-/settings/create-workspace" leftIcon={<AddIcon />}>
          Create New Workspace
        </Button>
      </CardHeader>
      <CardBody>
        <WorkspaceRow workspace={{ slug: user?.slug, orgname: user?.slug || '' }} />
        {workspaces?.orgs?.map((workspace) => <WorkspaceRow key={workspace.slug} workspace={workspace} />)}
        <ListDefaults
          isLoading={isFetching}
          nItems={workspaces?.orgs?.length}
          itemName="user"
          isFail={isError}
          hideNoResultsMsg={workspaces?.count === 0}
        />
      </CardBody>
      <MayhemPagination isLoading={isFetching} total={workspaces?.count || 0} prefix={paginationPrefix} />
    </Card>
  )
}
