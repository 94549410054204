import { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold'
  },
  sizes: {
    sm: { fontSize: 'sm' },
    md: { fontSize: 'md' },
    lg: { fontSize: 'lg' },
    xl: { fontSize: 'xl' },
    '2xl': { fontSize: '2xl' },
    '3xl': { fontSize: '3xl' }
  },
  defaultProps: {
    size: '3xl'
  },
  variants: {
    cardHeading: {
      fontSize: 'lg'
    }
  }
}
