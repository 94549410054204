import { useCallback } from 'react'
import { IconButton, useToast } from '@chakra-ui/react'
import DeleteIcon from '@material-design-icons/svg/sharp/delete.svg?react'

import { getMessageFromRtkError } from '@/util/errors'

interface Props {
  onDelete: () => void
  slug: string
  projectSlug: string
  deleteAction: () => Promise<void>
  currentUserHasPermission: boolean
  isDeleting: boolean
  entity: 'User' | 'Team'
}

export default function PermissionDeleteAction({ onDelete, slug, projectSlug, isDeleting, currentUserHasPermission, deleteAction, entity }: Props) {
  const toast = useToast()

  const handleDeleteClick = useCallback(async () => {
    try {
      await deleteAction()
      onDelete()

      toast({
        title: `${entity} Membership Revoked`,
        description: `${entity} "${slug}" was removed from project "${projectSlug}"`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      toast({
        title: `Revoke ${entity} Membership Failed`,
        description: getMessageFromRtkError(err),
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }, [deleteAction, entity, onDelete, projectSlug, slug, toast])

  return (
    <IconButton
      variant="outline"
      aria-label={`Delete ${entity}`}
      border="none"
      icon={<DeleteIcon />}
      isDisabled={isDeleting || !currentUserHasPermission}
      isLoading={isDeleting}
      onClick={handleDeleteClick}
    />
  )
}
