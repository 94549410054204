import { useLocation } from 'wouter'

import { Opaque } from '../opaque'

import { useQuery } from '../hooks'

import { cleanInteger } from './numbers'

/**
 * Takes in a URL along with an object of key/value pairs to use for query params and returns the fully formatted URL.
 * Filters out null/undefined query param values.
 * @param {string} base - the base URL
 * @param {object} params - param key/values object
 * @returns {string} the input URL with the query params added
 */
export function stringifyUrl(base: string, params = {}): string {
  const filteredParams = Object.entries(params).filter(([_key, value]) => value !== null && value !== undefined)
  const paramStr = filteredParams.map(([key, value]) => `${key}=${value}`).join('&')
  if (paramStr) {
    return `${base}?${paramStr}`
  }
  return `${base}`
}

/**
 * Modifies the location with the query param set to the provided value.
 * If null is provided for the value, remove the specified query param.
 */
export function setQueryParam({
  location,
  queryParams,
  param,
  value
}: {
  location: string
  queryParams: URLSearchParams
  param: string
  value: string | null | undefined
}) {
  const newQueryParams = new URLSearchParams(queryParams)
  if (value !== null && value !== undefined) {
    newQueryParams.set(param, value.toString())
  } else {
    newQueryParams.delete(param)
  }
  const entries = Object.fromEntries(newQueryParams.entries())
  return stringifyUrl(location, entries)
}

/**
 * Modifies the location with the specified query params/values.
 * If null is provided for the value of a param, remove that param.
 */
export function setQueryParams({
  location,
  queryParams,
  params
}: {
  location: string
  queryParams: URLSearchParams
  params: Array<{ param: string; value: string | null | undefined }>
}) {
  const newQueryParams = new URLSearchParams(queryParams)
  for (const paramValuePair of params) {
    const { param, value } = paramValuePair
    if (value !== null && value !== undefined) {
      newQueryParams.set(param, value.toString())
    } else {
      newQueryParams.delete(param)
    }
  }
  const entries = Object.fromEntries(newQueryParams.entries())
  return stringifyUrl(location, entries)
}

/**
 * Modifies the location with the page query param set to the provided value
 */
export function setPageQueryParam(location: string, queryParams: URLSearchParams, page: string | number | undefined, prefix = '') {
  return setQueryParam({ location, queryParams, param: `${prefix}page`, value: cleanInteger(page).toString() })
}

export type UpdateQuery = { [key: string]: string | null | undefined | number }
export function updateQueryParamsUrl(
  location: string, // base URL of the string
  queryParamUpdates: UpdateQuery, // the query parameters that are being added, changed, or, if set to `undefined` or `null`, deleted
  currentQuery: URLSearchParams // the query parameters of the browser right now
): string {
  return stringifyUrl(location, { ...Object.fromEntries(currentQuery.entries()), ...queryParamUpdates })
}

type UpdateQueryParamsFn = (queryParamUpdates: UpdateQuery) => void

export function useUpdateQueryParams(): UpdateQueryParamsFn {
  const [location, setLocation] = useLocation()
  const currentQuery = useQuery()

  return (queryParamUpdates: UpdateQuery): void => {
    setLocation(updateQueryParamsUrl(location, queryParamUpdates, currentQuery))
  }
}

// base64 encode/decode used for CI Build URLs
export type Base64Encoded = Opaque<string, 'Base64Encoded'>

export function b64EncodeUnicode(str: string): Base64Encoded {
  return btoa(encodeURIComponent(str)) as Base64Encoded
}

export function unicodeDecodeB64(str: string): string {
  return decodeURIComponent(atob(str))
}
type Filters = {
  [key: string]: unknown
}

export const setNewPageFilters = (filterKey: string, newFilter: unknown, filters: Filters, location: string): string => {
  if (filters[filterKey] !== newFilter) {
    const newFilters = { ...filters, [filterKey]: newFilter !== 'all' ? newFilter : null }
    return stringifyUrl(location, newFilters)
  }
  return stringifyUrl(location, filters)
}

export const splitCommaDelimitedParam = (param: string | undefined | null): string[] => {
  if (!param) {
    return []
  }
  return param.split(',')
}

export const commaJoinParamArray = (params: string[] | undefined | null): string | undefined => {
  if (!params || !params.length) {
    return undefined
  }
  return params.join(',')
}
