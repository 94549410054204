import { Center, Container } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { Page } from '../Page'

import { Footer } from './Footer'

export function MinimalLayout({ children }: { children: ReactNode }) {
  return (
    <Page height="full" showWidgets={false}>
      <Center flexDirection="column" minH="100vh">
        <Container maxW="container.xl">{children}</Container>
        <Footer />
      </Center>
    </Page>
  )
}
