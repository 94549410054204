import fetch from 'isomorphic-fetch'
import isPlainObject from 'lodash/isPlainObject'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import camelCase from 'lodash/camelCase'

import UserService from './features/auth/UserService'
import { getCookie } from './cookies'
import store from './redux/store'

export const mayhemDownMessage = 'Connection to Mayhem is lost. Please check back in a few minutes.'
const isProduction = import.meta.env.PROD

export type DirectionParam = 'asc' | 'desc'

export const getSortByParam = (sortedBy: string | undefined, orderedBy: DirectionParam | undefined, reverse?: boolean): string | undefined => {
  if (sortedBy && orderedBy) {
    return `${orderedBy === (reverse ? 'asc' : 'desc') ? '-' : ''}${sortedBy}`
  }

  return undefined
}

export class APIWarning {
  name: string
  message: string
  response: Response | undefined
  type: string

  // takes in an error and spits out a warning instead
  constructor(e: { name?: string; message?: string; response?: Response } = {}) {
    const { name, message, response } = e

    this.name = name || ''
    this.message = message || ''
    this.response = response
    this.type = 'APIWarning'

    if (isProduction) {
      console.warn(this.toString())
    }
  }

  toString() {
    return `API Warning: ${this.name} | ${this.message}`
  }
}

export function errCatch<T>(e: ResponseError<T>, _store: typeof store) {
  const { response = {} } = e
  const { json } = response as EvaluatedResponse<T>

  return new Promise<T>((resolve, reject) => {
    if (json as unknown) {
      reject(new APIWarning(e))
    } else {
      reject(new APIWarning({ name: 'Connection error', message: mayhemDownMessage }))
    }
  })
}

export type ResponseError<T> = Error & {
  response: Response | EvaluatedResponse<T>
}

export function checkStatus<T>(response: EvaluatedResponse<T> | DockerResponse<T> | Record<string, never> = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    const { json = {} } = response as { json?: { message?: string } }

    if (response.status && response.status >= 200 && response.status < 300) {
      resolve(response.json as unknown as T)
    } else {
      const error = new Error(json.message) as ResponseError<T>
      error.response = response as Response
      reject(error)
    }
  })
}

export function ensureJson<T extends Response>(response: T): Promise<T> {
  const { headers } = response

  return new Promise<T>((resolve, reject) => {
    if (headers?.get('content-type') === 'application/json') {
      resolve(response as T)
    } else {
      reject(new APIWarning({ name: 'Connection error', message: mayhemDownMessage }))
    }
  })
}

/**
 * Rescursively formats incoming data from API to convert underscored strings
 * to camel case and convert date strings to numbers.
 * @param obj - the object being processed
 * @param key - the key of the object being processed
 * @returns the newly formatted object
 */
export function formatData<T>(obj: Record<string, unknown> | string | number | Date, key = ''): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => formatData(item)) as unknown as T
  }

  if (!isPlainObject(obj)) {
    // convert date strings to numbers, maintain null
    return key.endsWith('At') && obj !== null ? (new Date(obj as string | number).getTime() as unknown as T) : (obj as unknown as T)
  }

  // makes sure user-provided env vars for Mayhemfiles preserve their appropriate casing
  const result = mapKeys(obj as Record<string, unknown>, (v, k) => (key === 'envVars' ? k : camelCase(k)))

  return mapValues(result, (value, _key) => formatData(value as Record<string, unknown>, _key)) as T
}

// Returns default headers that should be sent with every request
// to the API.
export async function defaultApiHeaders() {
  const state = store.getState()
  let headers: Record<string, string | undefined> = {
    'X-Mayhem-Client-Version': state.config.apiVersion
  }

  // NOTE: Unlike `mayhemApi.prepareHeaders` - we are NOT waiting on the
  // state to be updated to check whether keycloak is initialized or not.
  // This is because the code that checks keycloak initialization depends
  // on this specific function for fetching settings from the API (which
  // would result in a dependency cycle).

  if (!!state.config.keycloakInitialized && UserService.isLoggedInToKeycloak()) {
    await UserService.updateKeycloakToken()
    if (UserService.getKeycloakBearerToken() !== undefined) {
      headers = { Authorization: `Bearer ${UserService.getKeycloakBearerToken()}`, ...headers }
    }
  }
  return headers as HeadersInit
}

export type EvaluatedResponse<T> = Response & { json: Promise<T> | T }

export async function get<TResponse>(path: string): Promise<TResponse> {
  try {
    const fetchResponse = await fetch(path, {
      credentials: 'same-origin',
      headers: await defaultApiHeaders()
    })
    const jsonResponse = await ensureJson(fetchResponse)
    const json = await jsonResponse.json()
    const evaluatedResponse = jsonResponse as EvaluatedResponse<TResponse>
    evaluatedResponse.json = formatData(json)
    return await checkStatus(evaluatedResponse)
  } catch (err) {
    return await errCatch(err as ResponseError<TResponse>, store)
  }
}

type DockerResponse<T> = Response & {
  json: { digest?: unknown } & (Promise<T> | T)
  parsedHeaders: Record<string, unknown>
}

export function fetchDocker<TResponse>(path: string, options: Record<string, unknown> = {}): Promise<TResponse> {
  const { authHeader, acceptHeader, method = 'GET', formatResponse = true } = options
  return fetch(path, {
    method: method as string | undefined,
    headers: {
      Authorization: authHeader as string | undefined,
      Accept: acceptHeader as string | undefined
    } as HeadersInit
  })
    .then((response) => {
      // successful DELETE calls to Docker registries have no body, so no need to parse
      if (method === 'DELETE' && response.status === 202) {
        return response as DockerResponse<TResponse>
      }
      return response.json().then((json) => {
        const r = response as DockerResponse<TResponse>
        r.json = formatResponse ? formatData(json) : json

        // header entries need parsed out of the provided iterator
        const parsedHeaders: Record<string, unknown> = {}
        r.headers.forEach((value, name) => {
          parsedHeaders[name] = value

          if (acceptHeader && name === 'docker-content-digest') {
            // the digest is given in the form "sha256:<digest>"
            r.json.digest = value
          }
        })
        r.parsedHeaders = parsedHeaders

        return r
      })
    })
    .then((response) => checkStatus(response))
    .catch((err) => errCatch(err, store))
}

export async function post<TResponse>(path: string, params: Record<string, unknown> = {}, options: Record<string, unknown> = {}): Promise<TResponse> {
  const { method = 'POST' } = options

  const cookiedParams = params
  cookiedParams.token = getCookie('token')
  const bodyjson = JSON.stringify(cookiedParams)
  const headers = {
    'Content-Type': 'application/json',
    ...(await defaultApiHeaders())
  }

  try {
    const fetchResponse = await fetch(path, {
      method: method as string,
      body: bodyjson,
      headers,
      credentials: 'same-origin'
    })
    const jsonResponse = await ensureJson(fetchResponse)
    const json = await jsonResponse.json()
    const evaluatedResponse = jsonResponse as EvaluatedResponse<TResponse>
    evaluatedResponse.json = formatData(json)
    return await checkStatus(evaluatedResponse)
  } catch (err) {
    return await errCatch(err as ResponseError<TResponse>, store)
  }
}

export async function postFile<TResponse>(path: string, params: BodyInit | null | undefined): Promise<TResponse> {
  const body = params

  try {
    const headers = await defaultApiHeaders()
    const fetchResponse = await fetch(path, {
      method: 'POST',
      body,
      headers: headers,
      credentials: 'same-origin'
    })
    const jsonResponse = await ensureJson(fetchResponse)
    const json = await jsonResponse.json()
    const evaluatedResponse = jsonResponse as EvaluatedResponse<TResponse>
    evaluatedResponse.json = json
    return await checkStatus(evaluatedResponse)
  } catch (err) {
    return await errCatch(err as ResponseError<TResponse>, store)
  }
}

export function put<TResponse>(path: string, params = {}): Promise<TResponse> {
  return post<TResponse>(path, params, { method: 'PUT' })
}

export function del(path: string, params = {}) {
  return post(path, params, { method: 'DELETE' })
}

export async function head(path: string) {
  const headers = {
    'Content-Type': 'application/json',
    ...(await defaultApiHeaders())
  }

  return fetch(path, {
    method: 'HEAD',
    headers,
    credentials: 'same-origin'
  })
    .then((resp) => {
      return resp.status === 200
    })
    .catch((err) => errCatch(err, store))
}
