import { Select, SelectProps } from '@chakra-ui/react'

interface Props extends SelectProps {
  register?: object
}

export default function PermissionSelect({ register, ...props }: Props) {
  return (
    <Select placeholder="Select a permission..." {...register} variant="outline" {...props}>
      <option value="READ">Read</option>
      <option value="WRITE">Write</option>
      <option value="ADMIN">Admin</option>
    </Select>
  )
}
