import React, { useEffect } from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import PrintIcon from '@material-design-icons/svg/sharp/print.svg?react'
import { Link as WouterLink, useLocation } from 'wouter'

import ListFooter from '../../components/ListFooter'
import { Page } from '../../components/Page'
import MayhemTableLoader from '../../components/MayhemTableLoader'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useAppDispatch } from '../../hooks'
import { useGetRunQuery, useGetDefectsByRunQuery } from '../../redux/api/runs'
import { getRunDefectReportPageTitle } from '../../titles'
import { cleanInteger } from '../../util/numbers'

import { DefectReport } from '../defect-report/DefectReport'
import ProjectPermissionGuard from '../project/ProjectPermissionGuard'

import { RunDefectRow } from './RunDefectRow'

interface Props {
  workspace: string
  project: string
  target: string
  run: string
}

export function RunDefectsReportPage({ workspace, project, target, run }: Props) {
  const dispatch = useAppDispatch()
  const [location, setLocation] = useLocation()

  let runNumber: number
  try {
    runNumber = Number.parseInt(run, 10)
  } catch {
    throw new Error(`Invalid run number: ${run}`)
  }

  const { data: runData } = useGetRunQuery({ owner: workspace, projectSlug: project, targetSlug: target, runNumber })
  const n_defects = runData?.n_defects || 0
  const {
    data: defectsData,
    isLoading,
    isError
  } = useGetDefectsByRunQuery(
    {
      owner: workspace,
      projectSlug: project,
      targetSlug: target,
      runNumber,
      perPage: 50000
    },
    { skip: !runData }
  )

  useEffect(() => {
    document.title = getRunDefectReportPageTitle(workspace, project, target, runNumber)
  }, [dispatch, location, workspace, project, runNumber, setLocation, target])

  if (!runData || !defectsData) {
    return <Skeleton />
  }

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/${project}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={project} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/${project}/${target}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={target} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={WouterLink} to={`/${workspace}/${project}/${target}/${cleanInteger(runNumber)}`}>
              Run {cleanInteger(runNumber)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Defects Report</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading>
        <HStack gap={8} marginBottom={8}>
          <Text>Defects</Text>
          <Button
            leftIcon={<PrintIcon />}
            onClick={() => window.print()}
            css={{
              '@media print': {
                display: 'none'
              }
            }}
          >
            Print
          </Button>
        </HStack>
      </Heading>

      {isLoading ? (
        <MayhemTableLoader />
      ) : (
        <ProjectPermissionGuard owner={workspace} projectSlug={project}>
          <React.Fragment>
            <Card>
              <CardHeader>
                <Heading variant="cardHeading">Overview</Heading>
              </CardHeader>
              <CardBody p={8}>
                <Stack gap={0}>
                  <Text>
                    {n_defects} defect{n_defects !== 1 && 's'} found in this run.&nbsp;
                  </Text>
                  <TableContainer id="defect-list">
                    <Table>
                      <Thead>
                        <Tr>
                          <Th width="50%" textAlign="left">
                            Defect
                          </Th>
                          <Th width="50%" textAlign="right">
                            Example Test Case
                          </Th>
                        </Tr>
                      </Thead>
                    </Table>
                  </TableContainer>
                  {defectsData?.defects?.map((defect) => (
                    <RunDefectRow
                      key={defect.defect_number}
                      owner={workspace}
                      projectSlug={project}
                      targetSlug={target}
                      runNumber={runNumber}
                      defect={defect}
                      printable
                    />
                  ))}
                  <ListFooter isLoading={isLoading} nItems={defectsData?.defects?.length || 0} itemName="defect" isFail={isError} mini />
                </Stack>
              </CardBody>
            </Card>
            {n_defects > 0 && (
              <React.Fragment>
                <Divider my="8" />
                <Heading size="xl" marginBottom={4}>
                  Defect Details
                </Heading>
                {defectsData?.defects?.map((defect) => (
                  <DefectReport
                    key={defect.defect_number}
                    owner={workspace}
                    projectSlug={project}
                    targetSlug={target}
                    runNumber={runNumber}
                    defect={defect}
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        </ProjectPermissionGuard>
      )}
    </Page>
  )
}
