import { useEffect } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'

import { GridItem, SimpleGrid } from '@chakra-ui/react'

import { Feature } from 'flagged'

import { getAdminSettingsPageTitle } from '../../titles'

import { useAppDispatch } from '../../hooks'

import { FEATURE_ADMIN_ANNOUNCEMENTS } from '../../featureFlags'

import { AllowedDomainsForm } from './AllowedDomainsForm'
import MayhemBotCard from './MayhemBotCard'
import { SetAnnouncementForm } from './SetAnnouncementForm'
import { EnrollmentCard } from './EnrollmentCard'
import { MayhemSupportCard } from './MayhemSupportCard'
import { SystemResourceQuotaForm } from './SystemResourceQuotaForm'

export function AdminSettings() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = getAdminSettingsPageTitle('General')
  }, [dispatch])

  return (
    <SimpleGrid columns={2} spacing={8}>
      <GridItem rowSpan={2} colSpan={{ base: 2, lg: 1 }}>
        <EnrollmentCard />
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 1 }} h="100%">
        <MayhemSupportCard />
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 1 }}>
        <Card h="100%">
          <CardHeader>
            <Heading variant="cardHeading">Audit Log</Heading>
          </CardHeader>
          <CardBody p={8}>
            <Text>This .csv file contains a list of settings changes made by Mayhem Admins.</Text>
            <br />
            <a href="/api/v2/audit/audit_events.csv" download>
              <Button variant="solid">Download Audit Log (.csv)</Button>
            </a>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={2}>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Allowed Domains</Heading>
          </CardHeader>
          <CardBody p={8}>
            <AllowedDomainsForm />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 1 }}>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Resource Limits</Heading>
          </CardHeader>
          <CardBody p={8}>
            <SystemResourceQuotaForm />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 1 }}>
        <MayhemBotCard />
      </GridItem>
      <Feature name={FEATURE_ADMIN_ANNOUNCEMENTS}>
        <GridItem colSpan={2}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Announcement</Heading>
            </CardHeader>
            <CardBody p={8}>
              <SetAnnouncementForm />
            </CardBody>
          </Card>
        </GridItem>
      </Feature>
    </SimpleGrid>
  )
}
