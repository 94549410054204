import { Box, HStack, Tooltip, useToken } from '@chakra-ui/react'
import React from 'react'

import { formatNumber } from '../../util/numbers'

export type Severity = 'low' | 'medium' | 'high'
export type CapitalizedSeverity = 'High' | 'Medium' | 'Low'

export interface DefectsTagV2Props {
  count: number
  label?: string
  severity: CapitalizedSeverity | 'Total'
  tagLabel?: string
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
}

const computeSeverityScheme = (severity: string) => {
  if (severity === 'Total') {
    return 'subtleBg'
  }
  if (severity === 'Low') {
    return '-severityLow'
  }

  const scheme = `severity${severity}`

  return `${scheme}.500`
}

export const DefectsTagV2 = ({ count, label, severity = 'High', tagLabel, onClick }: DefectsTagV2Props) => {
  const formattedLongCount = formatNumber(count, { shorten: false })
  const labelWithCount = label ? `${formattedLongCount} ${label}` : `${formattedLongCount}`

  const [severityColor] = useToken('colors', [computeSeverityScheme(severity)])

  if (!tagLabel) {
    tagLabel = severity.slice(0, 1)
  }

  return (
    <Tooltip label={labelWithCount}>
      <HStack gap={0} fontSize={12} role={onClick && 'button'} aria-label={onClick && `Filter to ${severity}`} onClick={onClick}>
        <Box
          px={2}
          bg={severityColor}
          borderTopLeftRadius="lg"
          borderBottomLeftRadius="lg"
          color={severity === 'Total' ? undefined : 'white'}
          textAlign="center"
        >
          {tagLabel}
        </Box>
        <Box width="fit-content" paddingX={2} bg="subtleBg" borderTopRightRadius="lg" borderBottomRightRadius="lg" textAlign="center">
          {formatNumber(count)}
        </Box>
      </HStack>
    </Tooltip>
  )
}
