import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['user', 'internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      whoami: build.query<WhoamiApiResponse, WhoamiApiArg>({
        query: () => ({ url: `/api/v2/user/me` }),
        providesTags: ['user']
      }),
      getUserSetting: build.query<GetUserSettingApiResponse, GetUserSettingApiArg>({
        query: () => ({ url: `/api/v2/user-setting` }),
        providesTags: ['internal']
      }),
      putUserSetting: build.mutation<PutUserSettingApiResponse, PutUserSettingApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user-setting`, method: 'PUT', body: queryArg.userSetting }),
        invalidatesTags: ['internal']
      }),
      getKeycloakConfig: build.query<GetKeycloakConfigApiResponse, GetKeycloakConfigApiArg>({
        query: () => ({ url: `/api/v2/user-setting/keycloak.json` }),
        providesTags: ['internal']
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/account` }),
        providesTags: ['internal']
      }),
      putUser: build.mutation<PutUserApiResponse, PutUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/account`, method: 'PUT', body: queryArg.putUser }),
        invalidatesTags: ['internal']
      }),
      putUserQuota: build.mutation<PutUserQuotaApiResponse, PutUserQuotaApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/quota`, method: 'PUT', body: queryArg.userQuota }),
        invalidatesTags: ['internal']
      }),
      postOrg: build.mutation<PostOrgApiResponse, PostOrgApiArg>({
        query: (queryArg) => ({ url: `/api/v2/org`, method: 'POST', body: queryArg.org }),
        invalidatesTags: ['internal']
      }),
      getOrgs: build.query<GetOrgsApiResponse, GetOrgsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/org`, params: { offset: queryArg.offset, per_page: queryArg.perPage, q: queryArg.q } }),
        providesTags: ['internal']
      }),
      putOrg: build.mutation<PutOrgApiResponse, PutOrgApiArg>({
        query: (queryArg) => ({ url: `/api/v2/org/${queryArg.orgSlug}`, method: 'PUT', body: queryArg.orgPut }),
        invalidatesTags: ['internal']
      }),
      deleteOrg: build.mutation<DeleteOrgApiResponse, DeleteOrgApiArg>({
        query: (queryArg) => ({ url: `/api/v2/org/${queryArg.orgSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      getAccountStats: build.query<GetAccountStatsApiResponse, GetAccountStatsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/stat` }),
        providesTags: ['internal']
      }),
      getOrgMembers: build.query<GetOrgMembersApiResponse, GetOrgMembersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/user`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage, sorted_by: queryArg.sortedBy }
        }),
        providesTags: ['internal']
      }),
      getOrgMember: build.query<GetOrgMemberApiResponse, GetOrgMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/user/${queryArg.userSlug}` }),
        providesTags: ['internal']
      }),
      putOrgMember: build.mutation<PutOrgMemberApiResponse, PutOrgMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/user/${queryArg.userSlug}`,
          method: 'PUT',
          body: queryArg.orgMemberOwnerStatus
        }),
        invalidatesTags: ['internal']
      }),
      deleteOrgMember: build.mutation<DeleteOrgMemberApiResponse, DeleteOrgMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/user/${queryArg.userSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      getOrgsByUser: build.query<GetOrgsByUserApiResponse, GetOrgsByUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/org`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      }),
      getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}` }),
        providesTags: ['internal']
      }),
      postLocation: build.mutation<PostLocationApiResponse, PostLocationApiArg>({
        query: (queryArg) => ({ url: `/api/v2/page`, method: 'POST', body: queryArg.location }),
        invalidatesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type WhoamiApiResponse = /** status 200 Alias for getting the user information for the currently logged
    in user */ User
export type WhoamiApiArg = void
export type GetUserSettingApiResponse = /** status 200 Returns the global enrollment settings */ UserSetting
export type GetUserSettingApiArg = void
export type PutUserSettingApiResponse = /** status 200 Update the global enrollment settings */ UserSetting
export type PutUserSettingApiArg = {
  userSetting: UserSetting
}
export type GetKeycloakConfigApiResponse = /** status 200 Get the keycloak realm config json document for redirecting the
    web UI to the appropriate realm for authentication */ KeycloakSetting
export type GetKeycloakConfigApiArg = void
export type GetUserApiResponse = /** status 200
    Returns all account information for the user.
    Admins can see other users.
     */ User
export type GetUserApiArg = {
  userSlug: string
}
export type PutUserApiResponse = /** status 200
    Updates any user info. Only administrators and the user itself
    have the ability to change user information.
     */ User
export type PutUserApiArg = {
  userSlug: string
  putUser: PutUser
}
export type PutUserQuotaApiResponse = /** status 200 Set user resource quota.

    Logged-in user must be an admin.

    :param user_slug: the user_slug of the user to modify

    :returns: the modified user object as a response
     */ User
export type PutUserQuotaApiArg = {
  userSlug: string
  userQuota: UserQuota
}
export type PostOrgApiResponse = /** status 200 Creates a single organization, and returns creation data. */ Org
export type PostOrgApiArg = {
  org: Org
}
export type GetOrgsApiResponse = /** status 200 Fetch all orgs from the database */ OrgList
export type GetOrgsApiArg = {
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  q?: string
}
export type PutOrgApiResponse = /** status 200 Update a single organization */ Org
export type PutOrgApiArg = {
  orgSlug: string
  orgPut: OrgPut
}
export type DeleteOrgApiResponse = /** status 202 Mark an organization for deletion. Actual deletion will happen later as part
    of garbage collection */ AcceptedResult
export type DeleteOrgApiArg = {
  orgSlug: string
}
export type GetAccountStatsApiResponse = /** status 200 Get stats for the referenced account */ AccountStats
export type GetAccountStatsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
}
export type GetOrgMembersApiResponse = /** status 200 Return all org members. */ OrgMembers
export type GetOrgMembersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  sortedBy?: ('name' | '-name' | 'email' | '-email' | 'role' | '-role')[]
}
export type GetOrgMemberApiResponse = /** status 200 Get membership info of a user within an organization */ OrgMemberPut
export type GetOrgMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  userSlug: string
}
export type PutOrgMemberApiResponse = /** status 200 Alter a member's owner status in an org. */ OrgMemberPut
export type PutOrgMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  userSlug: string
  orgMemberOwnerStatus: OrgMemberOwnerStatus
}
export type DeleteOrgMemberApiResponse = /** status 200 Remove a member from an org */ Ok
export type DeleteOrgMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  userSlug: string
}
export type GetOrgsByUserApiResponse = /** status 200 Return the orgs of the user */ OrgList
export type GetOrgsByUserApiArg = {
  userSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetAccountApiResponse = /** status 200 Retrieves account information related to a owner */ Account
export type GetAccountApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
}
export type PostLocationApiResponse = /** status 200
    Send the current page location.
     */ Ok
export type PostLocationApiArg = {
  location: Location
}
export type AccountNotificationPreferencesV1 = {
  weekly_project_summaries?: boolean
  free_events_and_training?: boolean
  daily_defect_updates?: boolean
}
export type AccountNotificationPreferences = {
  V1: AccountNotificationPreferencesV1
}
export type User = {
  created_at?: string
  updated_at?: string
  user_id?: number
  slug: string
  owner?: string
  last_login_at?: string | null
  last_activity?: string | null
  is_admin?: boolean
  active?: boolean
  email_verified?: boolean
  is_moderator?: boolean
  email_address?: string | null
  has_oauth_login?: boolean
  has_atlassian_auth?: boolean
  invite_date?: string | null
  invited_by?: string | null
  invite_status?: string | null
  max_active_runs?: number
  max_run_duration?: number
  username: string
  first_name?: string | null
  last_name?: string | null
  location?: string | null
  language?: string | null
  avatar?: string | null
  last_seen_onboarding_version?: number | null
  last_seen_onboarding_at?: string | null
  notification_preferences?: AccountNotificationPreferences
  status: 'Invite Accepted' | 'Invite Revoked' | 'Invite Expired' | 'Invited' | 'Active' | 'Inactive' | 'Pending Verification'
}
export type ApiError = {
  message?: string
}
export type UserSetting = {
  created_at?: string
  updated_at?: string
  local_enrollment_enabled?: boolean
  google_oauth_enabled?: boolean
  okta_oauth_enabled?: boolean
  github_oauth_enabled?: boolean
  keycloak_enabled?: boolean
  password_strength_enabled?: boolean
  user_delegation_enabled?: boolean
  registry_access_scoping_enabled?: boolean
  enrollment_domain_allowlist?: string
  google_oauth_available?: boolean
  okta_oauth_available?: boolean
  github_oauth_available?: boolean
  keycloak_available?: boolean
  email_sending_available?: boolean
  max_active_runs?: number | null
  max_run_duration?: number | null
  mbot_utilization?: number | null
  mbot_task_duration?: number
  mbot_owner?: string
}
export type KeycloakSetting = {
  realm: string
  'auth-server-url': string
  'ssl-required': string
  resource: string
  'public-client': boolean
  'confidential-port': number
  in_mayhem_kubernetes_cluster: boolean
}
export type PutUser = {
  first_name?: string
  last_name?: string
  location?: string
  language?: string
  password?: string
  current_password?: string
  notification_preferences?: AccountNotificationPreferences
}
export type UserQuota = {
  created_at?: string
  updated_at?: string
  max_active_runs?: number
  max_run_duration?: number
}
export type Org = {
  created_at?: string
  updated_at?: string
  orgname: string
  description?: string | null
  slug?: string
  owns?: string
}
export type OrgList = {
  created_at?: string
  updated_at?: string
  orgs?: Org[]
  count?: number
}
export type OrgPut = {
  orgname?: string
  description?: string
  worker_pool_id?: number | null
}
export type AcceptedResult = {
  result?: string | null
}
export type DefectBreakdownStat = {
  created_at?: string
  updated_at?: string
  count: number
  title?: string | null
}
export type AccountStats = {
  created_at?: string
  updated_at?: string
  user_count: number
  project_count: number
  target_count: number
  testcase_count: number
  defect_count: number
  tests_run: number
  defect_breakdown: DefectBreakdownStat[]
}
export type OrgMember = {
  created_at?: string
  updated_at?: string
  slug: string
  owns?: boolean
  username?: string
  first_name?: string | null
  last_name?: string | null
  email?: string | null
  active?: boolean
  creator?: boolean
  avatar?: string | null
}
export type OrgMembers = {
  created_at?: string
  updated_at?: string
  users?: OrgMember[]
  count?: number
}
export type OrgMemberPut = {
  created_at?: string
  updated_at?: string
  slug: string
  owns?: boolean
  username?: string
  first_name?: string
  last_name?: string
  active?: boolean
}
export type OrgMemberOwnerStatus = {
  created_at?: string
  updated_at?: string
  owns: boolean
}
export type Ok = {
  result?: string
}
export type Account = {
  created_at?: string
  updated_at?: string
  slug?: string
  owner?: string
  is_org?: boolean
  orgname?: string
  description?: string | null
  creator_account_id?: number | null
  worker_pool_id: number | null
}
export type Location = {
  url: string
  title?: string
  workspace?: string
}
