import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Link as ChakraLink,
  HStack,
  Heading,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react'
import { Base64 } from 'js-base64'

import { Link as WouterLink } from 'wouter'

import CweDefaultIcon from '../../images/defaultissue.svg?react'

import { Page } from '../../components/Page'
import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { DefectStatusBadge } from '../../features/defect/DefectStatusBadge'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetMdsbomDefectQuery, useGetMdsbomWorkspaceReportsQuery } from '../../redux/api/mdsbom'
import { cleanInteger } from '../../util/numbers'

import { DefectSeverityBadge } from '../defect/DefectSeverityBadge'
import { DsbomReportRow } from '../dsbom-reports/DsbomReportRow'

interface Props {
  workspace: string
  imageRepoId: string
  defectId: string
}

export function DsbomDefectPage({ workspace, imageRepoId, defectId }: Props) {
  const query = useQuery()
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const page = cleanInteger(query.get('page'), 1)

  const { data: defectData, isFetching, isLoading } = useGetMdsbomDefectQuery({ workspace, imageRepoId, defectId })
  const { title, severity, severity_level: severityLevel, cve_url: cveUrl, observed } = defectData || {}

  const { data: derivedReportSets } = useGetMdsbomWorkspaceReportsQuery({
    workspace,
    imageRepoId,
    vulnerabilityCursors: [defectId],
    offset: (page - 1) * perPage,
    perPage
  })

  const { data: lastDerivedReportSets } = useGetMdsbomWorkspaceReportsQuery({ workspace, imageRepoId, vulnerabilityCursors: [defectId], perPage: 1 })

  const lastDerivedReportSet = lastDerivedReportSets?.reports[0]

  const imageRepoName = Base64.decode(imageRepoId)

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/-/dynamic-sbom`}>
              <BreadcrumbLink>Dynamic SBOM</BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/-/dynamic-sbom/${imageRepoId}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={imageRepoName} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Defect {defectId}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Stack spacing={4}>
        <Skeleton isLoaded={!isLoading}>
          <Stack>
            <HStack>
              <CweDefaultIcon />
              <Heading>{title}</Heading>
              <Text color="faded">{defectId}</Text>
              <DefectSeverityBadge severityLevel={severityLevel?.toLowerCase() as 'high' | 'medium' | 'low'} severity={severity} />
              <DefectStatusBadge state={observed ? 'open' : 'closed'} />
            </HStack>
            {cveUrl && (
              <ChakraLink href={cveUrl} isExternal>
                {cveUrl}
              </ChakraLink>
            )}
            <Divider />
          </Stack>
        </Skeleton>
        {/* <Card>
          <CardHeader>
            <Heading variant="cardHeading">Description</Heading>
          </CardHeader>
          <CardBody padding={4}>{description}</CardBody>
        </Card>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">References</Heading>
          </CardHeader>
          <CardBody padding={4}>{references}</CardBody>
        </Card> */}
        <Skeleton isLoaded={!isLoading}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Last seen in (from Report {lastDerivedReportSet?.id})</Heading>
            </CardHeader>
            <CardBody padding={4}>
              <Text>
                <b>Image: </b>
                {lastDerivedReportSet?.image_references}
              </Text>
            </CardBody>
          </Card>
        </Skeleton>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Reports</Heading>
            <Text color="faded">Reports in which the CVE appears in</Text>
          </CardHeader>
          <CardBody>
            {!isFetching &&
              derivedReportSets?.reports.map((reportSet) => <DsbomReportRow key={reportSet.id} workspace={workspace} report={reportSet} />)}
            <ListDefaults isLoading={isFetching} nItems={derivedReportSets?.count || 0} itemName="report" />
          </CardBody>
          <MayhemPagination isLoading={isFetching} total={derivedReportSets?.count || 0} />
        </Card>
      </Stack>
    </Page>
  )
}
