import { useMemo } from 'react'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Container, Heading, Stack, VStack, Link } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { Page } from '../../components/Page'
import OutsideLink from '../../components/OutsideLink'

import { RunApiDefects } from '../run-api-defects/RunApiDefects'
import { useGetDefectsByRunQuery, useGetRunQuery, useGetRunEndpointsQuery } from '../../redux/api/runs'

import { DataTab, DataTabs } from '../../components/DataTabs'
import { RunBreadcrumb } from '../run/RunBreadcrumb'

import { Requests } from '../requests/Requests'

import { endpointDetailUrl } from './utils'
import { EndpointSynopsis } from './EndpointSynopsis'
import { StatusHits, statusHitsFromRun } from './ResponsePanel'
import { Latencies, latenciesFromRun } from './LatencyPanel'

interface Props {
  workspace: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  endpointMethod: string
  endpointPath: string
}
export function EndpointDetailsPage(props: Props) {
  const { workspace: owner, projectSlug, targetSlug, runNumber, endpointMethod } = props
  const endpointPath = decodeURIComponent(props.endpointPath)

  const { data: defectsList } = useGetDefectsByRunQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber,
    perPage: 1,
    endpointMethodPaths: JSON.stringify([{ method: endpointMethod, path: endpointPath }])
  })
  const numDefects = defectsList?.count

  const { data: run } = useGetRunQuery({ owner, projectSlug, targetSlug, runNumber })
  const { data: runEndpoints } = useGetRunEndpointsQuery({ owner, projectSlug, targetSlug, runNumber })

  const runEndpoint = runEndpoints?.endpoints.find((endpoint) => endpoint.method === endpointMethod && endpoint.path === endpointPath)

  const [latencies, statusBucketHits]: [Latencies | undefined, StatusHits | undefined] = useMemo(() => {
    return [latenciesFromRun(run, endpointMethod, endpointPath), statusHitsFromRun(run, endpointMethod, endpointPath)]
  }, [run, endpointMethod, endpointPath])

  const sampleRequestsTabUrl = endpointDetailUrl(owner, projectSlug, targetSlug, runNumber, endpointMethod, endpointPath, '/tests')

  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <RunApiDefects {...{ owner, ...props }} endpointMethodPath={{ method: endpointMethod, path: endpointPath }} />,
      route: endpointDetailUrl(owner, projectSlug, targetSlug, runNumber, endpointMethod, endpointPath)
    },
    {
      label: 'Sample Requests',
      content: <Requests {...{ owner, ...props }} endpointMethodPath={{ method: endpointMethod, path: endpointPath }} />,
      route: sampleRequestsTabUrl
    }
  ]

  return (
    <Page header={<RunBreadcrumb workspace={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} runNumberAsLink />}>
      <Stack gap={4}>
        <Heading display="inline-block" noOfLines={1} wordBreak="break-all" textOverflow="ellipsis">
          {endpointMethod} {endpointPath}
        </Heading>
        <hr />

        {!runEndpoint?.covered && (
          <Alert status="warning">
            <AlertIcon />
            <VStack alignItems="begin">
              <AlertTitle>Shallow Coverage</AlertTitle>
              <AlertDescription>
                Mayhem was unable to get a 2xx response from this endpoint. See below for{' '}
                <Link as={WouterLink} to={sampleRequestsTabUrl} textDecoration="underline">
                  sample requests
                </Link>{' '}
                made to this endpoint. Refer to{' '}
                <OutsideLink href="https://app.mayhem.security/docs/api-testing/tutorials/optimizing-test-coverage/api-success/">
                  Successful Coverage in our docs
                </OutsideLink>{' '}
                for more details on how to increase your endpoint coverage with Mayhem.
              </AlertDescription>
            </VStack>
          </Alert>
        )}

        <EndpointSynopsis {...{ numDefects, latencies, statusBucketHits }} />

        <Container width="full" maxW="full" padding={0}>
          <DataTabs variant="line" isLazy width="full" tabs={tabData} />
        </Container>
      </Stack>
    </Page>
  )
}
